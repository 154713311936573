import { DependencyList, EffectCallback, useEffect } from 'react';

export const useDebounceEffect = (effect: EffectCallback, deps?: DependencyList, debounceTimeMS: number = 0) => {
  const wrappedEffect =
    effect instanceof Function
      ? () => {
          let returnValue: any;

          const timeoutId = setTimeout(() => {
            returnValue = effect();
          }, debounceTimeMS);

          return () => {
            clearTimeout(timeoutId);

            if (returnValue instanceof Function) {
              returnValue();
            }
          };
        }
      : effect;
  const useEffectArgs = deps ? [wrappedEffect, [effect, debounceTimeMS, ...deps]] : [wrappedEffect];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(...(useEffectArgs as [any]));
};
