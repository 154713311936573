import React, { FC, useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { AddPosition, PositionsLayout, PositionsList } from './Positions/Layout';
import { ObjectController } from '../Utils/ObjectController';
import { Position } from '../Types/Position';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { ItemViewController } from '../Utils/ItemController';
import { PositionForm } from './Positions/Form';
import { ExpandMore } from '@material-ui/icons';

export const getObjectController = (
  editing: boolean = false,
  creatingPosition?: Position,
  changingPosition?: Position,
  viewController?: ItemViewController<Position>
): ObjectController<Position> | undefined => {
  const method: Function | undefined = editing
    ? viewController?.getChangingItemObjectController
    : viewController?.getCreatingItemObjectController;
  const position: Position | undefined = editing ? changingPosition : creatingPosition;

  return method && position && position.id && method(position.id);
};

export type PositionsProps = {
  pending?: boolean;
  positions?: Position[];
  creatingPosition?: Position;
  changingPosition?: Position;
  viewController?: ItemViewController<Position>;
  hideForm?: boolean;
  isAdmin?: boolean;
};

export const Positions: FC<PositionsProps> = ({
  pending = false,
  positions = [],
  creatingPosition,
  changingPosition,
  viewController,
  hideForm = false,
}) => {
  const editing: boolean = !!changingPosition;
  const position: Position | undefined = editing ? changingPosition : creatingPosition;
  const objectController: ObjectController<Position> | undefined = getObjectController(
    editing,
    creatingPosition,
    changingPosition,
    viewController
  );
  const formTitle = editing ? 'Update Position' : 'Add a Position';
  const formSubmitLabel = editing ? 'Save' : 'Add';
  const showCancelButton: boolean = editing;
  const showEditButtons: boolean = !editing;
  const onSelectEditInternal = useCallback(
    ({ currentTarget: { value } }) => {
      if (viewController) {
        viewController.initChangingItem(value);
      }
    },
    [viewController]
  );
  const onDeleteInternal = useCallback(
    ({ currentTarget: { value } }) => {
      if (viewController) {
        viewController.onDeleteItem(value);
      }
    },
    [viewController]
  );

  useEffect(() => {
    if (!creatingPosition && viewController) {
      viewController.initCreatingItem();
    }
  }, [creatingPosition, viewController]);

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PositionsLayout>
        {!hideForm ? (
          <AddPosition>
            <PositionForm
              formTitle={formTitle}
              showCancelButton={showCancelButton}
              formSubmitLabel={formSubmitLabel}
              position={position}
              objectController={objectController}
            />
          </AddPosition>
        ) : undefined}
        <PositionsList>
          <Typography variant="h5">All Positions</Typography>
          <List>
            {positions.map((p) => (
              <ListItem key={`Position:${p.id}`}>
                <ListItemText>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>{p.name}</AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">{p.description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </ListItemText>
                {!hideForm ? (
                  <>
                    {showEditButtons && (
                      <ListItemIcon>
                        <IconButton value={p.id} onClick={onSelectEditInternal}>
                          <Edit />
                        </IconButton>
                      </ListItemIcon>
                    )}
                    <ListItemIcon>
                      <IconButton value={p.id} onClick={onDeleteInternal}>
                        <Delete />
                      </IconButton>
                    </ListItemIcon>
                  </>
                ) : undefined}
              </ListItem>
            ))}
          </List>
        </PositionsList>
      </PositionsLayout>
    </>
  );
};
