import React from 'react';
import { createLayout, createLayoutPosition } from '../../App/PrefabLayout';
import { Box } from '@material-ui/core';

export const AddPosition = createLayoutPosition();
export const PositionsList = createLayoutPosition();

export type PositionsLayoutProps = {};

export const PositionsLayout = createLayout((props: PositionsLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="stretch">
        <AddPosition />
      </Box>
      <br />
      <br />
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <PositionsList />
      </Box>
    </Box>
  );
});
