import React, { useCallback, useMemo, useState } from 'react';
import { createLayout, createLayoutPosition } from './PrefabLayout';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { useMediaWidth } from '../Utils/MediaWidth';
import { MIN_DESKTOP_WIDTH } from '../Constants';
import { Menu } from '@material-ui/icons';

const HiddenWhenEmptyBox = styled(Box)`
  &:empty {
    display: none;
  }
`;

export const Title = createLayoutPosition();
export const UserControls = createLayoutPosition();
export const NavigationControls = createLayoutPosition();
export const ViewControls = createLayoutPosition();
export const ViewTopPanel = createLayoutPosition();
export const View = createLayoutPosition();
export const Footer = createLayoutPosition();

export type AppLayoutProps = {
  viewOnly?: boolean;
  loading?: boolean;
};

const HEADER_PADDING = '1em';

export const AppLayout = createLayout(({ viewOnly, loading, ...otherProps }: AppLayoutProps) => {
  const [viewControlsOpen, setViewControlsOpen] = useState<boolean>(false);
  const mediaWidth = useMediaWidth();
  const isDesktopWidth = useMemo(() => mediaWidth >= MIN_DESKTOP_WIDTH, [mediaWidth]);
  const onToggleViewControls = useCallback(
    () => setViewControlsOpen(!viewControlsOpen),
    [viewControlsOpen, setViewControlsOpen]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="stretch"
      width="100vw"
      height={isDesktopWidth ? '100vh' : `${window.innerHeight}px`}
      overflow="hidden"
      {...otherProps}
    >
      {loading ? (
        <Box
          flex="1 1 auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!viewOnly && (
            <Box
              flex="0 0 auto"
              display="grid"
              gridTemplateColumns="3fr 1fr"
              alignItems="center"
              width="auto"
              color="#ffffff"
              style={{
                backgroundColor: '#1d2651',
              }}
            >
              <Box
                padding={HEADER_PADDING}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Title />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" padding={HEADER_PADDING}>
                <UserControls />
              </Box>
            </Box>
          )}
          {!viewOnly ? (
            <Box
              flex="0 0 auto"
              width="auto"
              height="auto"
              color="#ffffff"
              style={{
                backgroundColor: '#000000',
              }}
            >
              <NavigationControls />
            </Box>
          ) : undefined}
          <Box
            flex="1 1 auto"
            display={isDesktopWidth ? 'grid' : 'flex'}
            flexDirection={!isDesktopWidth ? 'column' : undefined}
            alignItems={!isDesktopWidth ? 'stretch' : undefined}
            justifyContent={!isDesktopWidth ? 'stretch' : undefined}
            gridTemplateColumns={viewOnly ? '1fr' : isDesktopWidth ? '1fr 5fr' : undefined}
            width="auto"
            height="auto"
            overflow="hidden"
          >
            {!viewOnly && (
              <Box
                flex={isDesktopWidth ? '1 1 auto' : '0 0 auto'}
                overflow="auto"
                maxHeight={isDesktopWidth ? undefined : '10em'}
                style={{
                  backgroundColor: '#eeeeee',
                }}
                onClick={isDesktopWidth ? undefined : onToggleViewControls}
              >
                {isDesktopWidth ? (
                  <ViewControls />
                ) : viewControlsOpen ? (
                  <ViewControls />
                ) : (
                  <IconButton>
                    <Menu fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )}
            <Box
              id="main-view-box"
              flex="1 1 auto"
              width="auto"
              height="auto"
              overflow="auto"
              padding={viewOnly ? '' : '2em'}
            >
              <ViewTopPanel />
              <View />
            </Box>
          </Box>
          <HiddenWhenEmptyBox
            flex="1 0 auto"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="flex-start"
            width="auto"
            padding="2em"
            color="#ffffff"
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#000000',
            }}
          >
            <Footer />
          </HiddenWhenEmptyBox>
        </>
      )}
    </Box>
  );
});
