import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { createLayout, createLayoutPosition } from '../../App/PrefabLayout';

export const Logo = createLayoutPosition();
export const Title = createLayoutPosition();
export const HeaderRight = createLayoutPosition();
export const Details = createLayoutPosition();

export type LayoutProps = {};

export const Layout = createLayout((props: LayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Box display="flex" flexDirection="row" alignItems="stretch" justifyContent="stretch">
        <Box
          flex="1 0 auto"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          gridGap="0.5em"
        >
          <Logo />
          <Typography variant="h5">
            <Title />
          </Typography>
        </Box>
        <HeaderRight />
      </Box>
      <Divider
        variant="middle"
        style={{
          margin: '2em',
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <Details />
      </Box>
    </Box>
  );
});
