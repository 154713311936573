import { Profile } from '../../Types/Profile';
import { Position } from '../../Types/Position';
import { Designation } from '../../Types/Designation';
import { WORK_ROLE_REGION_LIST } from '../WorkRoles/Constants';

export type ProfileFieldOptionTransform = {
  fromString: (value: string) => any;
  toString: (value: any) => string;
};

export type ProfileFieldOption = {
  label: string;
  value: keyof Profile;
  type: string;
  transform?: ProfileFieldOptionTransform;
};

export const NUMBER_TRANSFORM: ProfileFieldOptionTransform = {
  fromString: (value) => (value ? parseInt(value, 10) : undefined),
  toString: (value) => (typeof value === 'number' ? `${value}` : ''),
};

export const IDENTITY_TRANSFORM: ProfileFieldOptionTransform = {
  fromString: (v) => v,
  toString: (v) => v,
};

export const getProfileFieldOptions = ({
  positions = [],
  designations = [],
}: {
  positions?: Position[];
  designations?: Designation[];
}): ProfileFieldOption[] => [
  {
    label: 'First Name',
    value: 'firstName',
    type: 'text',
  },
  {
    label: 'Last Name',
    value: 'lastName',
    type: 'text',
  },
  {
    label: 'Email',
    value: 'email',
    type: 'email',
  },
  {
    label: 'Positions Of Interest',
    value: 'positionsOfInterest',
    type: 'text',
    transform: {
      fromString: (value): Partial<Position>[] | undefined => {
        const position = positions.find((p) => p.id === value);

        return position ? [position] : undefined;
      },
      toString: ([position]: Position[] = []) => position?.id || '',
    },
  },
  {
    label: 'Regions Of Interest',
    value: 'regionsOfInterest',
    type: 'text',
    transform: {
      fromString: (value): string[] | undefined => {
        const region = WORK_ROLE_REGION_LIST.find((r) => r.id === value);

        return region ? [region.id] : undefined;
      },
      toString: ([regionId]: string[] = []) => regionId || '',
    },
  },
  {
    label: 'Years Of Experience',
    value: 'yearsOfExperience',
    type: 'number',
    transform: NUMBER_TRANSFORM,
  },
  {
    label: 'Profile Summary',
    value: 'profileSummary',
    type: 'text',
  },
  {
    label: 'Salary',
    value: 'salary',
    type: 'number',
    transform: NUMBER_TRANSFORM,
  },
  {
    label: 'Designations',
    value: 'designations',
    type: 'text',
    transform: {
      fromString: (value): Partial<Designation>[] | undefined => {
        const designation = designations.find((d) => d.id === value);

        return designation ? [designation] : undefined;
      },
      toString: ([designation]: Designation[] = []) => designation?.id || '',
    },
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    type: 'tel',
    transform: {
      fromString: (value = '') => `${value.replace(/[^0-9]/gim, () => '')}`,
      toString: (value = '') => value.replace(/[^0-9]/gim, () => ''),
    },
  },
];
