import React, { FC } from 'react';
import { LifePod } from '@incarnate/react';
import { DBServiceClient } from '../../../Utils/DBServiceClient';
import { Profile } from '../../../Types/Profile';
import { Designation } from '../../../Types/Designation';
import { FileService } from '../../../Services/FileService';
import { AppEnvConfig } from '../../Types';
import { EmployerProfile } from '../../../Types/EmployerProfile';

export type UserServicesProps = {
  appEnvConfig: AppEnvConfig;
};

export const UserServices: FC<UserServicesProps> = ({ appEnvConfig }) => {
  return (
    <>
      <LifePod
        name="UserEmployerProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<EmployerProfile>(`${appEnvConfig.api.baseUrl}/api/employer-profile`, idToken);
        }}
      />
      <LifePod
        name="UserProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/api/profile`, idToken);
        }}
      />
      <LifePod
        name="UserPosition"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/api/position`, idToken);
        }}
      />
      <LifePod
        name="UserDesignation"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Designation>(`${appEnvConfig.api.baseUrl}/api/designation`, idToken);
        }}
      />
      <LifePod
        name="UserResume"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new FileService(
            `${appEnvConfig.api.baseUrl}/api/resume`,
            idToken,
            '/get-upload-url',
            '/resume-exists'
          );
        }}
      />
      <LifePod
        name="UserWorkRole"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/api/work-role`, idToken);
        }}
      />
    </>
  );
};
