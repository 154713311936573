export interface WorkRoleRegion {
  id: string;
  label: string;
  focusArea: string;
}

/**
 * IMPORTANT: Each region `id` must be encapsulated with `[]` brackets for accurate search results.
 * */
export const WORK_ROLE_REGION_MAP: { [key: string]: WorkRoleRegion } = {
  GREATER_BOSTON_NEW_ENGLAND: {
    id: '[GREATER_BOSTON_NEW_ENGLAND]',
    label: 'Greater Boston/New England',
    focusArea: 'Boston, Providence, Hartford',
  },
  NEW_YORK_METRO_TRI_STATE: {
    id: '[NEW_YORK_METRO_TRI_STATE]',
    label: 'New York Metro/Tri-State',
    focusArea: 'New York, Fairfield County, CT, Long Island, Northern New Jersey',
  },
  SOUTHERN_CALIFORNIA: {
    id: '[SOUTHERN_CALIFORNIA]',
    label: 'Southern California',
    focusArea: 'Los Angeles and San Diego',
  },
  GREATER_PHILADELPHIA: {
    id: '[GREATER_PHILADELPHIA]',
    label: 'Greater Philadelphia',
    focusArea: 'Philadelphia, Southern New Jersey, King of Prussia',
  },
  GREATER_CHICAGO: {
    id: '[GREATER_CHICAGO]',
    label: 'Greater Chicago',
    focusArea: 'Chicago and Milwaukee',
  },
  ATLANTA: {
    id: '[ATLANTA]',
    label: 'Atlanta',
    focusArea: 'Atlanta',
  },
  DENVER: {
    id: '[DENVER]',
    label: 'Denver',
    focusArea: 'Denver',
  },
  REMOTE_VIRTUAL: {
    id: '[REMOTE_VIRTUAL]',
    label: 'Remote/Virtual',
    focusArea: 'Remote/Virtual',
  },
  THE_BAY_AREA: {
    id: '[THE_BAY_AREA]',
    label: 'The Bay Area (San Francisco/Oakland/San Jose)',
    focusArea: 'San Francisco, Oakland, San Jose',
  },
  SOUTH_FLORIDA: {
    id: '[SOUTH_FLORIDA]',
    label: 'South Florida',
    focusArea: 'Miami, Fort Lauderdale, West Palm Beach',
  },
  CENTRAL_FLORIDA: {
    id: '[CENTRAL_FLORIDA]',
    label: 'Central Florida (Tampa/Orlando)',
    focusArea: 'Tampa, Orlando',
  },
  SEATTLE_PUGET_SOUND: {
    id: '[SEATTLE_PUGET_SOUND]',
    label: 'Seattle/Puget Sound',
    focusArea: 'Seattle, Tacoma, Bellevue',
  },
  INDIANAPOLIS: {
    id: '[INDIANAPOLIS]',
    label: 'Indianapolis',
    focusArea: 'Indianapolis',
  },
  CHARLOTTE: {
    id: '[CHARLOTTE]',
    label: 'Charlotte',
    focusArea: 'Charlotte',
  },
  PHOENIX_SCOTTSDALE: {
    id: '[PHOENIX_SCOTTSDALE]',
    label: 'Phoenix/Scottsdale',
    focusArea: 'Phoenix, Scottsdale',
  },
  TWIN_CITIES: {
    id: '[TWIN_CITIES]',
    label: 'Twin Cities (Minneapolis/St.Paul)',
    focusArea: 'Minneapolis, St. Paul',
  },
  WASHINGTON_DC_BALTIMORE: {
    id: '[WASHINGTON_DC_BALTIMORE]',
    label: 'Washington DC/Baltimore',
    focusArea: 'Washington DC, Baltimore',
  },
  DALLAS_FORT_WORTH: {
    id: '[DALLAS_FORT_WORTH]',
    label: 'Dallas/Fort Worth',
    focusArea: 'Dallas, Fort Worth',
  },
  HOUSTON: {
    id: '[HOUSTON]',
    label: 'Houston',
    focusArea: 'Houston',
  },
  SAN_ANTONIO: {
    id: '[SAN_ANTONIO]',
    label: 'San Antonio',
    focusArea: 'San Antonio',
  },
  GREATER_DETROIT: {
    id: '[GREATER_DETROIT]',
    label: 'Greater Detroit',
    focusArea: 'Detroit, Ann Arbor, Flint',
  },
};

export const WORK_ROLE_REGION_LIST: WorkRoleRegion[] = Object.keys(WORK_ROLE_REGION_MAP).map(
  (k) => WORK_ROLE_REGION_MAP[k]
);
