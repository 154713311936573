import React, { FC } from 'react';
import { Incarnate, LifePod } from '@incarnate/react';
import { FileService } from '../Services/FileService';

export type FileControllerProps = {
  name: string;
  shared: {
    FileService: string;
  };
};

export class FileViewController {
  constructor(
    public service: FileService,
    public setUploading: (value: boolean) => void,
    public setError: (value: any) => void,
    public invalidateFileExists: () => void
  ) {}

  uploadFile = async (file: File, getUploadUrlArgs: any[] = []) => {
    this.setUploading(true);
    this.setError(undefined);

    try {
      await this.service.uploadFile(file, getUploadUrlArgs);
    } catch (error) {
      this.setError(error);
    }

    this.setUploading(false);
    this.invalidateFileExists();
  };
}

/**
 * Input:
 * FileService: @see FileService
 *
 * Available:
 * ViewController: @see FileViewController
 * */
export const FileController: FC<FileControllerProps> = ({ name, shared }) => {
  return (
    <Incarnate name={name} shared={shared}>
      <LifePod name="Uploading" factory={() => false} />
      <LifePod name="Error" factory={() => false} />
      <LifePod name="FileExistsError" factory={() => false} />
      <LifePod name="FileExistsLoading" factory={() => false} />
      <LifePod
        name="Activity"
        dependencies={{
          uploading: 'Uploading',
          fileExistsLoading: 'FileExistsLoading',
        }}
        factory={(deps) => Object.keys(deps).reduce((acc, k) => acc || deps[k], false)}
      />
      <LifePod
        name="FileExists"
        dependencies={{
          service: 'FileService',
        }}
        setters={{
          setFileExistsError: 'FileExistsError',
          setFileExistsLoading: 'FileExistsLoading',
        }}
        strict
        factory={async (deps): Promise<boolean | undefined> => {
          const {
            service,
            setFileExistsError,
            setFileExistsLoading,
          }: {
            service: FileService;
            setFileExistsError: (value: any) => void;
            setFileExistsLoading: (value: boolean) => void;
          } = deps as any;

          let fileExists: boolean | undefined;

          setFileExistsLoading(true);

          try {
            fileExists = await service.fileExists();
          } catch (error) {
            setFileExistsError(error);
          }

          setFileExistsLoading(false);

          return fileExists;
        }}
      />
      <LifePod
        name="ViewController"
        dependencies={{
          service: 'FileService',
        }}
        setters={{
          setUploading: 'Uploading',
          setError: 'Error',
        }}
        invalidators={{
          invalidateFileExists: 'FileExists',
        }}
        strict
        factory={(deps) => {
          const {
            service,
            setUploading,
            setError,
            invalidateFileExists,
          }: {
            service: FileService;
            setUploading: (value: boolean) => void;
            setError: (value: any) => void;
            invalidateFileExists: () => void;
          } = deps as any;

          return new FileViewController(service, setUploading, setError, invalidateFileExists);
        }}
      />
    </Incarnate>
  );
};
