import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Avatar, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from '@material-ui/core';
import { ExitToApp, Menu as MenuIcon, OpenInNew } from '@material-ui/icons';
import { useMediaWidth } from './Utils/MediaWidth';
import { MIN_DESKTOP_WIDTH } from './Constants';
// @ts-ignore
import TOSContent from 'url:./User/TermsOfService/content.pdf';

export type UserControlsProps = {
  username?: string;
  signedIn?: boolean;
  onSignOut?: () => void;
};

export const UserControls: FC<UserControlsProps> = ({ username = '', signedIn, onSignOut }) => {
  const avatarInitial = useMemo(() => (username?.charAt(0) || '').toUpperCase(), [username]);
  const { palette: { primary: { main: primaryMainColor = '' } = {} } = {} } = useTheme();
  const mediaWidth = useMediaWidth();
  const isDesktopWidth = useMemo(() => mediaWidth >= MIN_DESKTOP_WIDTH, [mediaWidth]);
  const userButtonRef = useRef<HTMLButtonElement | HTMLDivElement>(null);
  const [userOptionsMenuOpen, setUserOptionsMenuOpen] = useState<boolean>(false);
  const onUserButtonClick = useCallback(() => {
    if (signedIn) {
      setUserOptionsMenuOpen(!userOptionsMenuOpen);
    }
  }, [signedIn, userOptionsMenuOpen, setUserOptionsMenuOpen]);
  const onUserOptionsMenuClose = useCallback(() => setUserOptionsMenuOpen(false), [setUserOptionsMenuOpen]);
  const onSignOutClicked = useCallback(() => {
    if (onSignOut) {
      onSignOut();
    }
  }, [onSignOut]);
  const onTOSClick = useCallback(() => window.open(TOSContent, '_blank'), []);

  return (
    <Box flex="0 0 auto">
      {isDesktopWidth ? (
        <Button
          ref={userButtonRef as any}
          onClick={onUserButtonClick}
          style={{ color: '#ffffff', fontSize: '0.75em', textTransform: 'none' }}
          color="primary"
          variant="contained"
          startIcon={<MenuIcon />}
        >
          {username}
        </Button>
      ) : (
        <Avatar
          ref={userButtonRef as any}
          onClick={onUserButtonClick}
          style={{ color: '#ffffff', backgroundColor: primaryMainColor, fontSize: '0.75em', textTransform: 'none' }}
          color="primary"
          variant="circle"
        >
          {avatarInitial}
        </Avatar>
      )}
      <Menu
        anchorEl={userButtonRef.current}
        open={userOptionsMenuOpen}
        onClose={onUserOptionsMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{
          marginTop: '0.5em',
        }}
      >
        <MenuItem onClick={onSignOutClicked}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </MenuItem>
        <MenuItem onClick={onTOSClick}>
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Terms & Privacy" />
        </MenuItem>
      </Menu>
    </Box>
  );
};
