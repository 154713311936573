import React, { FC } from 'react';
import { LifePod } from '@incarnate/react';
import { DBServiceClient } from '../../../Utils/DBServiceClient';
import { Position } from '../../../Types/Position';
import { WorkRole } from '../../../Types/WorkRole';
import { Designation } from '../../../Types/Designation';
import { Profile } from '../../../Types/Profile';
import { AppEnvConfig } from '../../Types';
import { FileService } from '../../../Services/FileService';
import { EmployerProfile } from '../../../Types/EmployerProfile';

export type EmployerServicesProps = {
  appEnvConfig: AppEnvConfig;
};

export const EmployerServices: FC<EmployerServicesProps> = ({ appEnvConfig }) => {
  return (
    <>
      <LifePod
        name="EmployerEmployerProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<EmployerProfile>(`${appEnvConfig.api.baseUrl}/employer/employer-profile`, idToken);
        }}
      />
      <LifePod
        name="EmployerLogo"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new FileService(`${appEnvConfig.api.baseUrl}/employer/employer-logo`, idToken);
        }}
      />
      <LifePod
        name="EmployerPosition"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Position>(`${appEnvConfig.api.baseUrl}/employer/position`, idToken);
        }}
      />
      <LifePod
        name="EmployerWorkRole"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<WorkRole>(`${appEnvConfig.api.baseUrl}/employer/work-role`, idToken);
        }}
      />
      <LifePod
        name="EmployerDesignation"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Designation>(`${appEnvConfig.api.baseUrl}/employer/designation`, idToken);
        }}
      />
      <LifePod
        name="EmployerProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/employer/profile`, idToken);
        }}
      />
      <LifePod
        name="EmployerWorkRoleProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/employer/work-role-profile`, idToken);
        }}
      />
    </>
  );
};
