export type ObjectValueGetter<ObjectType> = (() => ObjectType) & ((subPath: string) => any);
export type ObjectChangeHandler<ObjectType> = ((value: ObjectType) => void) & ((value: any, subPath: string) => void);
export type ObjectFieldChangeHandler<FieldValueType> = (fieldValue: FieldValueType) => void;

export interface ObjectFieldInputChangeEvent {
  target: {
    value: string;
  };
}

export type ObjectFieldInputValueTransform<FieldValueType> = (fieldValue: string) => FieldValueType;
export type ObjectFieldInputChangeHandler = (event: ObjectFieldInputChangeEvent) => void;

export class ObjectController<ObjectType> {
  private _subFieldObjectControllersMap: { [key: string]: ObjectController<any> } = {};
  private _fieldChangeHandlerMap: {
    [key: string]: ObjectFieldChangeHandler<any>;
  } = {};
  private _fieldInputChangeHandlerMap: {
    [key: string]: Map<ObjectFieldInputValueTransform<any> | undefined, ObjectFieldInputChangeHandler>;
  } = {};

  constructor(
    public getValue: ObjectValueGetter<ObjectType>,
    public onChange: ObjectChangeHandler<ObjectType>,
    public onSubmit: () => void,
    public onCancel: () => void
  ) {}

  onValueChange = (newValue: ObjectType) => {
    this.onChange(newValue);
  };

  getFieldChangeHandler = <FieldValueType>(fieldName: keyof ObjectType): ObjectFieldChangeHandler<FieldValueType> => {
    if (!this._fieldChangeHandlerMap[fieldName as string]) {
      this._fieldChangeHandlerMap[fieldName as string] = (fieldValue: FieldValueType): void => {
        const newValue = {
          ...this.getValue(),
          [fieldName]: fieldValue,
        };

        this.onValueChange(newValue);
      };
    }

    return this._fieldChangeHandlerMap[fieldName as string];
  };

  getFieldInputChangeHandler = (
    fieldName: keyof ObjectType & string,
    transform?: ObjectFieldInputValueTransform<any>
  ): ObjectFieldInputChangeHandler => {
    if (!this._fieldInputChangeHandlerMap[fieldName as string]) {
      this._fieldInputChangeHandlerMap[fieldName as string] = new Map();
    }

    if (!this._fieldInputChangeHandlerMap[fieldName as string].get(transform)) {
      this._fieldInputChangeHandlerMap[fieldName as string].set(transform, ({ target: { value } }): void => {
        const newFieldValue = transform ? transform(value) : value;

        this.getFieldChangeHandler(fieldName)(newFieldValue);
      });
    }

    return this._fieldInputChangeHandlerMap[fieldName as string].get(transform) as ObjectFieldInputChangeHandler;
  };

  getSubFieldObjectController = <SubObjectType>(
    subField: keyof ObjectType & string,
    onSubmit: () => void = () => undefined,
    onCancel: () => void = () => undefined
  ): ObjectController<SubObjectType> => {
    if (!this._subFieldObjectControllersMap[subField]) {
      this._subFieldObjectControllersMap[subField] = new ObjectController<SubObjectType>(
        () => this.getValue(subField),
        (newValue: SubObjectType) => this.onChange(newValue, subField),
        onSubmit,
        onCancel
      );
    }

    return this._subFieldObjectControllersMap[subField as string];
  };
}
