import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { createLayout, createLayoutPosition } from '../../App/PrefabLayout';

export const Controls = createLayoutPosition();
export const List = createLayoutPosition();

export type LayoutProps = {
  hideControls?: boolean;
};

export const Layout = createLayout(({ hideControls = false }: LayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Typography variant="h5">Profile Search</Typography>
      <br />
      {!hideControls ? (
        <>
          <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start" gridGap="1em">
            <Controls />
          </Box>
          <br />
          <Divider
            variant="middle"
            style={{
              margin: '2em',
            }}
          />
        </>
      ) : undefined}
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <List />
      </Box>
    </Box>
  );
});
