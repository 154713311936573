const RESTORE_URL_KEY = 'APPLICATION_RESTORE_URL';

export const saveRestoreUrl = () => {
  localStorage.setItem(RESTORE_URL_KEY, window.location.href);
};

export const getRestoreUrl = () => {
  return localStorage.getItem(RESTORE_URL_KEY);
};

export const removeRestoreUrl = () => {
  localStorage.removeItem(RESTORE_URL_KEY);
};

export const redirectToRestoreUrl = (): boolean => {
  const restoreUrl = getRestoreUrl();

  if (restoreUrl) {
    removeRestoreUrl();
    window.location.href = restoreUrl;

    return true;
  }

  return false;
};

export const automateRedirectToRestoreUrl = (saving = false): boolean => {
  if (saving) {
    saveRestoreUrl();
  } else {
    return redirectToRestoreUrl();
  }

  return false;
};
