import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Profile } from '../Types/Profile';
import { Controls, Layout, List } from './ProfileSearch/Layout';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ItemViewController } from '../Utils/ItemController';
import { ProfileListItem } from './ProfileSearch/ListItem';
// @ts-ignore
import EmptyState from 'url:./ProfileSearch/EmptyState.svg';
import styled from 'styled-components';
import { getProfileFieldOptions, IDENTITY_TRANSFORM, ProfileFieldOption } from './ProfileSearch/Constants';
import { useDebounceEffect } from '../Utils/ReactUtils';
import { Position } from '../Types/Position';
import { Designation } from '../Types/Designation';
import { WORK_ROLE_REGION_LIST, WorkRoleRegion } from './WorkRoles/Constants';

const SEARCH_FIELD_LABEL_ID = 'profile-search-field-select-label';

const EmptyStateImg = styled.img`
  margin: 0 auto;
  width: 30%;
`;

type ProfileSearchField = {
  field: keyof Profile;
  value: any;
};

const DEFAULT_PROFILE_SEARCH_FIELD_NAME = 'NO_FIELD_SELECTED';
const DEFAULT_PROFILE_SEARCH_FIELD: ProfileSearchField = { field: DEFAULT_PROFILE_SEARCH_FIELD_NAME as any, value: '' };

const getRemainingProfileSearchFieldOptions = (
  profileFieldOptions: ProfileFieldOption[] = [],
  selectedFieldNames: string[],
  field?: string
): ProfileFieldOption[] =>
  profileFieldOptions.filter((pSF) => (!!field && pSF.value === field) || !selectedFieldNames.includes(pSF.value));

type ProfileSearchFieldValueProps = {
  value: string;
  onChange: (value: string) => void;
};

const RegionOfInterestValue: FC<ProfileSearchFieldValueProps> = ({ value, onChange }) => {
  const regionInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (regionInputRef.current) {
      regionInputRef.current.setAttribute('autocomplete', 'new-password');
    }
  }, []);

  return (
    <Autocomplete
      options={WORK_ROLE_REGION_LIST}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, v) => option.id === v.id}
      value={value ? WORK_ROLE_REGION_LIST.find(({ id }) => id === value) : null}
      onChange={(_event, selectedRegion: WorkRoleRegion | null = null) => {
        onChange(selectedRegion ? selectedRegion.id : '');
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Region"
          placeholder="Select a focus area..."
          inputRef={regionInputRef}
        />
      )}
      renderOption={(option, _state) => (
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <Typography variant="body1">{option.label}</Typography>
          <Typography variant="caption">{option.focusArea}</Typography>
        </Box>
      )}
    />
  );
};

type ProfileSearchFieldProps = {
  disableValue?: boolean;
  disableRemove?: boolean;
  index: number;
  profileSearchField: ProfileSearchField;
  selectedProfileSearchFields?: ProfileSearchField[];
  onProfileSearchFieldChange: (profileSearchField: ProfileSearchField, index: number) => void;
  onRemoveProfileSearchField: (profileSearchField: ProfileSearchField) => void;
  positions?: Position[];
  designationOptions?: Designation[];
  profileFieldOptions?: ProfileFieldOption[];
};

const ProfileSearchFieldItem: FC<ProfileSearchFieldProps> = ({
  disableValue = false,
  disableRemove = false,
  index,
  profileSearchField,
  selectedProfileSearchFields = [],
  onProfileSearchFieldChange,
  onRemoveProfileSearchField,
  positions = [],
  designationOptions = [],
  profileFieldOptions = [],
}) => {
  const valueComponentMap = useMemo<Partial<Record<keyof Profile, FC<ProfileSearchFieldValueProps>>>>(
    () => ({
      positionsOfInterest: ({ value, onChange }) => (
        <Autocomplete
          options={positions}
          getOptionLabel={(option) => option.name || ''}
          getOptionSelected={(option, v) => option.id === v.id}
          value={value ? positions.find(({ id }) => id === value) : null}
          onChange={(_event, selectedPosition: Position | null = null) => {
            onChange(selectedPosition?.id ? selectedPosition.id : '');
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Position" placeholder="Select a title..." />
          )}
          renderOption={(option, _state) => (
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
              <Typography variant="body1">{option.name}</Typography>
              <Typography variant="caption">{option.description}</Typography>
            </Box>
          )}
        />
      ),
      designations: ({ value, onChange }) => (
        <Autocomplete
          options={designationOptions}
          getOptionLabel={(option) => option.label || ''}
          getOptionSelected={(option, v) => option.id === v.id}
          value={value ? designationOptions.find(({ id }) => id === value) : null}
          onChange={(_event, selectedDesignation: Designation | null = null) => {
            onChange(selectedDesignation?.id ? selectedDesignation.id : '');
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Licenses/Designations"
              placeholder="Add licenses and/or designations..."
            />
          )}
          renderOption={(option, _state) => (
            <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
              <Typography variant="body1">{option.label}</Typography>
            </Box>
          )}
        />
      ),
      regionsOfInterest: RegionOfInterestValue,
    }),
    [positions, designationOptions]
  );
  const { field = '' as any, value = '' }: Partial<ProfileSearchField> = profileSearchField || {};
  const selectedFieldItem = useMemo<ProfileFieldOption | undefined>(
    () => (field ? profileFieldOptions.filter((o) => o.value === field)[0] : undefined),
    [field, profileFieldOptions]
  );
  const {
    type: selectedFieldType = 'text',
    transform: {
      fromString: selectedFieldTransformFromString = IDENTITY_TRANSFORM.fromString,
      toString: selectedFieldTransformToString = IDENTITY_TRANSFORM.toString,
    } = IDENTITY_TRANSFORM,
  } = selectedFieldItem || {};
  const searchValue = useMemo(() => selectedFieldTransformToString(value), [selectedFieldTransformToString, value]);
  const onSelectedFieldChange = useCallback(
    ({ target: { value: field } }) => {
      onProfileSearchFieldChange(
        {
          field,
          value: undefined,
        },
        index
      );
    },
    [onProfileSearchFieldChange, index]
  );
  const onSearchInputChange = useCallback(
    (newValue: string) =>
      onProfileSearchFieldChange(
        {
          ...profileSearchField,
          value: selectedFieldTransformFromString(newValue),
        },
        index
      ),
    [onProfileSearchFieldChange, selectedFieldTransformFromString, profileSearchField, index]
  );
  const onRemoveProfileSearchFieldInternal = useCallback(
    () => onRemoveProfileSearchField(profileSearchField),
    [onRemoveProfileSearchField, profileSearchField]
  );
  const selectedFieldNames = useMemo(
    () => selectedProfileSearchFields.map((f) => f.field),
    [selectedProfileSearchFields]
  );
  const remainingProfileSearchFieldOptions = useMemo(
    () => getRemainingProfileSearchFieldOptions(profileFieldOptions, selectedFieldNames, field),
    [selectedFieldNames, field, profileFieldOptions]
  );
  const ValueComponent = useMemo<FC<ProfileSearchFieldValueProps>>(() => {
    const valueComponentForField = valueComponentMap[field];

    return valueComponentForField
      ? valueComponentForField
      : ({ value, onChange }) => (
          <TextField
            label="Search for..."
            autoFocus
            inputMode={selectedFieldType as any}
            type={selectedFieldType}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
          />
        );
  }, [field, valueComponentMap, selectedFieldType]);

  return (
    <Box flex="1 0 auto" display="grid" gridTemplateColumns="3fr 3fr 1fr" gridGap="1em">
      {disableValue ? <Box /> : <ValueComponent value={searchValue} onChange={onSearchInputChange} />}
      <Box flex="1 0 auto" display="flex" flexDirection="column" alignItems="stretch" justifyContent="stretch">
        <InputLabel id={SEARCH_FIELD_LABEL_ID}>Search in...</InputLabel>
        <Select
          disabled={!disableValue}
          labelId={SEARCH_FIELD_LABEL_ID}
          value={field as any}
          onChange={onSelectedFieldChange}
        >
          {disableValue ? <MenuItem value={DEFAULT_PROFILE_SEARCH_FIELD_NAME}>Select a Field...</MenuItem> : undefined}
          {remainingProfileSearchFieldOptions.map((o, i) => (
            <MenuItem key={`SearchFieldOption:${o.value}:${i}`} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {disableRemove ? <Box /> : <Button onClick={onRemoveProfileSearchFieldInternal}>Remove</Button>}
    </Box>
  );
};

export type ProfileSearchProps = {
  profileQuery?: Partial<Profile>;
  profileList?: Profile[];
  pending?: boolean;
  viewController?: ItemViewController<Profile>;
  onOpenProfileDetails?: (profileId: string) => void;
  hideSearchControls?: boolean;
  positionsPending?: boolean;
  designationOptionsPending?: boolean;
  positions?: Position[];
  designationOptions?: Designation[];
};

export const ProfileSearch: FC<ProfileSearchProps> = ({
  profileQuery,
  profileList = [],
  pending = false,
  viewController,
  onOpenProfileDetails,
  hideSearchControls = false,
  positionsPending = false,
  designationOptionsPending = false,
  positions = [],
  designationOptions = [],
}) => {
  const anyPending = pending || positionsPending || designationOptionsPending;
  const [profileQueryParams, setProfileQueryParams] = useState<Partial<Profile> | undefined>(profileQuery);
  const onItemSelect = useCallback(
    (profileId: string) => {
      if (profileId && onOpenProfileDetails) {
        onOpenProfileDetails(profileId);
      }
    },
    [onOpenProfileDetails]
  );
  const [profileSearchFieldList, setProfileSearchFieldListInternal] = useState<ProfileSearchField[]>([]);
  const setProfileSearchFieldList = useCallback(
    (newProfileSearchFieldList: ProfileSearchField[]) => {
      const newProfileQueryParams = newProfileSearchFieldList.reduce(
        (acc, pSF) =>
          pSF.value === '' || pSF.value === undefined || pSF.value === null
            ? acc
            : {
                ...acc,
                [pSF.field]: pSF.value,
              },
        {}
      );
      const newKeys = Object.keys(newProfileQueryParams);
      const oldKeys = Object.keys(profileQueryParams || {});
      const allKeys = [...newKeys, ...oldKeys];
      const cleanProfileQueryParams = profileQueryParams || {};
      const changedKeys = allKeys.filter(
        (k) => (newProfileQueryParams as any)[k] !== (cleanProfileQueryParams as any)[k]
      );

      setProfileSearchFieldListInternal(newProfileSearchFieldList);

      if (changedKeys.length > 0) {
        setProfileQueryParams(newProfileQueryParams);
      }
    },
    [setProfileSearchFieldListInternal, setProfileQueryParams, profileQueryParams]
  );
  const profileFieldOptions = useMemo(
    () =>
      getProfileFieldOptions({
        positions,
        designations: designationOptions,
      }),
    [positions, designationOptions]
  );
  const remainingProfileSearchFieldOptions = useMemo(
    () =>
      getRemainingProfileSearchFieldOptions(
        profileFieldOptions,
        profileSearchFieldList.map((f) => f.field)
      ),
    [profileSearchFieldList, profileFieldOptions]
  );
  const hasRemainingProfileSearchFieldOptions = useMemo(
    () => remainingProfileSearchFieldOptions.length > 0,
    [remainingProfileSearchFieldOptions]
  );
  const onAddProfileSearchField = useCallback(
    (profileSearchField: ProfileSearchField, _index: number) => {
      const fieldExists = profileSearchFieldList.reduce(
        (acc, pSF) => acc || pSF.field === profileSearchField.field,
        false
      );

      if (!fieldExists) {
        setProfileSearchFieldList([
          ...profileSearchFieldList,
          {
            ...profileSearchField,
            value: '',
          },
        ]);
      }
    },
    [profileSearchFieldList, setProfileSearchFieldList]
  );
  const onRemoveProfileSearchField = useCallback(
    (profileSearchField: ProfileSearchField) =>
      setProfileSearchFieldList(profileSearchFieldList.filter((pSF) => pSF.field !== profileSearchField.field)),
    [profileSearchFieldList, setProfileSearchFieldList]
  );
  const onProfileSearchFieldChange = useCallback(
    (profileSearchField: ProfileSearchField, index: number) => {
      const newProfileSearchFieldList = profileSearchFieldList.map((pSF, i) =>
        i === index ? profileSearchField : pSF
      );

      setProfileSearchFieldList(newProfileSearchFieldList);
    },
    [setProfileQueryParams, profileSearchFieldList, setProfileSearchFieldList]
  );

  useDebounceEffect(
    () => {
      if (profileQueryParams && viewController && profileQueryParams !== profileQuery) {
        viewController.onListParamsChange(profileQueryParams);
      }
    },
    [viewController, profileQueryParams, profileQuery],
    800
  );

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={anyPending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layout hideControls={hideSearchControls}>
        <Controls>
          {profileSearchFieldList.map((pSF, i) => (
            <ProfileSearchFieldItem
              key={`ProfileSearchFieldItem:${i}`}
              index={i}
              profileSearchField={pSF}
              selectedProfileSearchFields={profileSearchFieldList}
              onProfileSearchFieldChange={onProfileSearchFieldChange}
              onRemoveProfileSearchField={onRemoveProfileSearchField}
              positions={positions}
              designationOptions={designationOptions}
              profileFieldOptions={profileFieldOptions}
            />
          ))}
          {hasRemainingProfileSearchFieldOptions ? (
            <ProfileSearchFieldItem
              key={`ProfileSearchFieldItem:${profileSearchFieldList.length}`}
              disableValue
              disableRemove
              index={profileSearchFieldList.length}
              profileSearchField={DEFAULT_PROFILE_SEARCH_FIELD}
              selectedProfileSearchFields={profileSearchFieldList}
              onProfileSearchFieldChange={onAddProfileSearchField}
              onRemoveProfileSearchField={onRemoveProfileSearchField}
              positions={positions}
              designationOptions={designationOptions}
              profileFieldOptions={profileFieldOptions}
            />
          ) : undefined}
        </Controls>
        <List>
          {!profileList?.length ? <EmptyStateImg src={EmptyState} alt="No Profiles Listed" /> : undefined}
          {profileList.map((p: Profile, idx) => (
            <ProfileListItem key={`Profile:${idx}`} profile={p} onItemSelect={onItemSelect} />
          ))}
        </List>
      </Layout>
    </>
  );
};
