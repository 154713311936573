import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Button, Card, CardContent, CardMedia } from '@material-ui/core';
// @ts-ignore
import StackedLogoDarkBlue2020 from 'url:../Assets/Graphics/StackedLogoDarkBlue2020.svg';

const Base = styled.div`
  background-color: #efefef;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;
const StyledCardMedia = styled(CardMedia)`
  height: 15em;
`;
const ButtonBox = styled(Box)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 1em;
`;

export type LoginStartProps = {
  onLogin: () => void;
  onSignUp: () => void;
};

export const LoginStart: FC<LoginStartProps> = ({ onLogin, onSignUp }) => {
  return (
    <Base>
      <Card>
        <StyledCardMedia title="Lead-Advisor" image={StackedLogoDarkBlue2020} />
        <CardContent>
          <ButtonBox>
            <Button variant="contained" size="large" color="primary" onClick={onLogin}>
              Login
            </Button>
            <Button variant="contained" size="large" color="default" onClick={onSignUp}>
              Sign&nbsp;Up
            </Button>
          </ButtonBox>
        </CardContent>
      </Card>
    </Base>
  );
};
