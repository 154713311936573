import React, { FC } from 'react';
import { Details, HeaderRight, Layout, Logo, Title } from './EmployerProfileDetails/Layout';
import { Backdrop, Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Web, YouTube } from '@material-ui/icons';
import { shallowConvertNullToUndefined } from '../Utils/DataUtils';
import { EmployerProfile } from '../Types/EmployerProfile';
import styled from 'styled-components';
// @ts-ignore
import NoEmployerLogoYet from 'url:../Assets/Graphics/NoEmployerLogoYet.svg';

const EmployerLogo = styled.img`
  flex: 0 0 auto;
  height: 4em;
`;

export type EmployerProfileDetailsProps = {
  employerProfile?: EmployerProfile;
  pending?: boolean;
};

export const EmployerProfileDetails: FC<EmployerProfileDetailsProps> = ({ employerProfile = {}, pending = false }) => {
  const {
    logoUrl = '',
    name = '',
    youtubeVideoLink = '',
    websiteLink = '',
    description = '',
  } = shallowConvertNullToUndefined(employerProfile) as EmployerProfile;

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layout>
        <Logo>
          <EmployerLogo src={!!logoUrl ? logoUrl : NoEmployerLogoYet} />
        </Logo>
        <Title>{name}</Title>
        <HeaderRight>
          <Box flex="1 0 auto" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            {youtubeVideoLink ? (
              <IconButton href={youtubeVideoLink} target="_blank" title="YouTube Video" rel="noreferrer">
                <YouTube color="primary" fontSize="large" />
              </IconButton>
            ) : undefined}
            {websiteLink ? (
              <IconButton href={websiteLink} target="_blank" title="Website" rel="noreferrer">
                <Web color="primary" fontSize="large" />
              </IconButton>
            ) : undefined}
          </Box>
        </HeaderRight>
        <Details>
          <Box flex="1 1 auto" display="grid" gridTemplateColumns="2fr 1fr">
            <Box
              flex="1 1 auto"
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              style={{ padding: '1em', whiteSpace: 'pre-wrap' }}
            >
              <Typography variant="body1">{description}</Typography>
            </Box>
          </Box>
        </Details>
      </Layout>
    </>
  );
};
