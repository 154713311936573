import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createLayout, createLayoutPosition } from '../../App/PrefabLayout';

export const Controls = createLayoutPosition();
export const List = createLayoutPosition();

export type WorkRolesListLayoutProps = {};

export const WorkRolesListLayout = createLayout((props: WorkRolesListLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <Controls />
      </Box>
      <Typography variant="h5">All Roles</Typography>
      <br />
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <List />
      </Box>
    </Box>
  );
});
