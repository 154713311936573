import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Profile } from '../Types/Profile';
import { WORK_ROLE_REGION_LIST, WorkRoleRegion } from '../Employer/WorkRoles/Constants';
import { Position } from '../Types/Position';
import { ItemViewController } from '../Utils/ItemController';
import { ObjectController } from '../Utils/ObjectController';
import { shallowConvertNullToUndefined } from '../Utils/DataUtils';
import { FileViewController } from '../Utils/FileController';
import { ViewTopPanel } from '../App/Layout';
import { Help, Info } from '@material-ui/icons';
import { Designation } from '../Types/Designation';
import { useMediaWidth } from '../Utils/MediaWidth';
import { MIN_DESKTOP_WIDTH } from '../Constants';
import { SwitchBox } from '../Utils/SwitchBox';
import { FileUploadButton } from '../UIComponents/FileUploadButton';
import { getProfileIsValid, ProfileValidationResults, validateProfile } from './ProfileView/ValidationUtils';
import { useDebounceEffect } from '../Utils/ReactUtils';

const HELP_TEXT = {
  YOUTUBE_LINK: `
Create a 2 minute video to serve as a self summary.
Upload it to your YouTube account and share the link here.
HINT: When you upload, you can mark your video as unlisted so that only people you share it with will see it.
`,
  PROFILE_SUMMARY: `
Enter some descriptive text about yourself and your career.
Outline achievements related to your positions of interest.
`,
};

const { Portal: ViewTopPanelPortal } = ViewTopPanel;

export type ProfileViewProps = {
  originalProfile?: Profile;
  profile?: Profile;
  positionOptions?: Position[];
  designationOptions?: Designation[];
  viewController?: ItemViewController<Profile>;
  pending?: boolean;
  resumeViewController?: FileViewController;
  resumeUploaded?: boolean;
  onAfterDelete?: () => void;
};

export const ProfileView: FC<ProfileViewProps> = ({
  originalProfile,
  profile = {},
  positionOptions = [],
  designationOptions = [],
  viewController,
  pending = false,
  resumeViewController,
  resumeUploaded = false,
  onAfterDelete,
}) => {
  const mediaWidth = useMediaWidth();
  const isDesktopWidth = useMemo(() => mediaWidth >= MIN_DESKTOP_WIDTH, [mediaWidth]);
  const {
    id: profileId,
    email = '',
    positionsOfInterest = [],
    regionsOfInterest = [],
    yearsOfExperience = 0,
    youtubeVideoLink = '',
    linkedInLink = '',
    profileSummary = '',
    salary = 0,
    designations = [],
  } = shallowConvertNullToUndefined(profile) as Profile;
  const objectController: ObjectController<Profile> | undefined = profileId
    ? viewController?.getChangingItemObjectController(profileId)
    : undefined;
  const onPOIChange = useCallback(
    (_event, selectedOptions: Position[] = []) => {
      objectController?.getFieldChangeHandler('positionsOfInterest')([...selectedOptions]);
    },
    [objectController]
  );
  const onDesignationsChange = useCallback(
    (_event, selectedOptions: Designation[] = []) => {
      objectController?.getFieldChangeHandler('designations')([...selectedOptions]);
    },
    [objectController]
  );
  const onROIChange = useCallback(
    (_event, selectedOptions: WorkRoleRegion[] = []) => {
      const value: string[] = selectedOptions.map((o) => o.id);

      objectController?.getFieldChangeHandler('regionsOfInterest')(value);
    },
    [objectController]
  );
  const [resumeFile, setResumeFile] = useState<File | undefined>(undefined);
  const profileChanged: boolean = originalProfile ? profile !== originalProfile : false;
  const viewDataChanged: boolean = profileChanged || !!resumeFile;
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [confirmingEmail, setConfirmingEmail] = useState<string>('');
  const emailConfirmed = useMemo(() => email.toLowerCase() === confirmingEmail.toLowerCase(), [email, confirmingEmail]);
  const onConfirmingEmailChange = useCallback(
    ({ target: { value: newEmail = '' } }) => {
      setConfirmingEmail(newEmail);
    },
    [setConfirmingEmail]
  );
  const onShowDeleteAccount = useCallback(() => setShowDelete(true), [setShowDelete]);
  const onCancelDelete = useCallback(() => setShowDelete(false), [setShowDelete]);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const onConfirmDeleteAccount = useCallback(
    (event) => {
      event.preventDefault();

      if (profileId) {
        viewController?.onDeleteItem(profileId);
        setDeleteInProgress(true);
      }
    },
    [viewController, profileId, onAfterDelete, setDeleteInProgress]
  );
  const onReset = useCallback(() => {
    if (profileId && viewController) {
      viewController.initChangingItem(profileId);
    }

    setResumeFile(undefined);
  }, [profileId, viewController, setResumeFile]);
  const profileValidationResults: ProfileValidationResults = useMemo(
    () =>
      validateProfile({
        profile: shallowConvertNullToUndefined(profile) as Partial<Profile>,
        resumeExists: resumeUploaded,
        resumeFile,
        overrideWith: pending ? true : undefined,
      }),
    [profile, resumeUploaded, resumeFile, pending]
  );
  const {
    positionsOfInterest: poiValidationResults,
    regionsOfInterest: roiValidationResults,
    yearsOfExperience: yoeValidationResults,
    youtubeVideoLink: youtubeLinkValidationResults,
    linkedInLink: linkedInLinkValidationResults,
    profileSummary: profileSummaryValidationResults,
    salary: salaryValidationResults,
    designations: designationsValidationResults,
    resume: resumeValidationResults,
  } = profileValidationResults;
  const profileIsValid: boolean = useMemo(
    () => getProfileIsValid(profileValidationResults),
    [profileValidationResults]
  );
  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (profileChanged) {
        objectController?.onSubmit();
      }

      if (resumeFile) {
        await resumeViewController?.uploadFile(resumeFile);
        setResumeFile(undefined);
      }
    },
    [objectController, resumeViewController, resumeFile, profileChanged]
  );

  useDebounceEffect(
    () => {
      if (onAfterDelete && deleteInProgress && !pending) {
        onAfterDelete();
      }
    },
    [onAfterDelete, deleteInProgress, pending],
    1000
  );

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showDelete ? undefined : <Typography variant="h5">Profile</Typography>}
      <br />
      <Box
        display={showDelete ? 'none' : 'flex'}
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <SwitchBox mobileMode={!isDesktopWidth}>
          <Autocomplete
            multiple
            options={positionOptions}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            value={positionsOfInterest}
            onChange={onPOIChange}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Positions Of Interest"
                placeholder="Select some possible titles..."
                error={!poiValidationResults}
              />
            )}
            renderOption={(option) => (
              <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                <Typography variant="body1">{option.name}</Typography>
                &nbsp;
                <Tooltip title={<Typography variant="body1">{option.description}</Typography>}>
                  <Info color="action" />
                </Tooltip>
              </Box>
            )}
          />
          <Autocomplete
            multiple
            options={WORK_ROLE_REGION_LIST}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            value={WORK_ROLE_REGION_LIST.filter((r) => regionsOfInterest.indexOf(r.id) !== -1)}
            onChange={onROIChange}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Regions Of Interest"
                placeholder="Select some focus areas..."
                error={!roiValidationResults}
              />
            )}
            renderOption={(option, _state) => (
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <Typography variant="body1">{option.label}</Typography>
                <Typography variant="caption">{option.focusArea}</Typography>
              </Box>
            )}
          />
        </SwitchBox>
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <SwitchBox mobileMode={!isDesktopWidth} gridTemplateColumns="1fr 1fr 1fr 1fr">
          <TextField
            label="Years Of Experience"
            type="number"
            inputProps={{
              min: 0,
              step: 1,
            }}
            value={yearsOfExperience}
            onChange={objectController?.getFieldInputChangeHandler('yearsOfExperience')}
            error={!yoeValidationResults}
          />
          <Box />
          <FileUploadButton
            uploaded={resumeUploaded}
            file={resumeFile}
            onFileChange={setResumeFile}
            label="Resume"
            accept="application/pdf"
            error={!resumeValidationResults}
          />
        </SwitchBox>
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <SwitchBox mobileMode={!isDesktopWidth}>
          <TextField
            label="Desired Compensation ($/yr)"
            type="number"
            inputProps={{
              min: 0,
              step: 1,
            }}
            value={salary}
            onChange={objectController?.getFieldInputChangeHandler('salary')}
            error={!salaryValidationResults}
          />
          <Autocomplete
            multiple
            options={designationOptions}
            getOptionLabel={(option) => option.label || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            value={designations}
            onChange={onDesignationsChange}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Licenses/Designations"
                placeholder="Select your licenses and/or designations..."
                error={!designationsValidationResults}
              />
            )}
            renderOption={(option) => (
              <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                <Typography variant="body1">{option.label}</Typography>
              </Box>
            )}
          />
        </SwitchBox>
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <TextField
          label="YouTube Video Profile Link (2 Minutes)"
          placeholder="Example: https://www.youtube.com/watch?v=[video-id]"
          fullWidth
          value={youtubeVideoLink}
          onChange={objectController?.getFieldInputChangeHandler('youtubeVideoLink')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={<Typography variant="body1">{HELP_TEXT.YOUTUBE_LINK}</Typography>}>
                  <Help color="action" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          error={!youtubeLinkValidationResults}
        />
        <br />
        <TextField
          label="LinkedIn Profile"
          placeholder="Example: https://www.linkedin.com/in/[your-username]"
          fullWidth
          value={linkedInLink}
          onChange={objectController?.getFieldInputChangeHandler('linkedInLink')}
          error={!linkedInLinkValidationResults}
        />
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <TextField
          label="Profile Summary"
          placeholder="Write a few things about your experience, goals and what you're about."
          fullWidth
          multiline
          rows={8}
          value={profileSummary}
          variant="outlined"
          onChange={objectController?.getFieldInputChangeHandler('profileSummary')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={<Typography variant="body1">{HELP_TEXT.PROFILE_SUMMARY}</Typography>}>
                  <Help color="action" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          error={!profileSummaryValidationResults}
        />
        {viewDataChanged ? (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ display: 'none' }}
            disabled={!profileIsValid}
          >
            Save
          </Button>
        ) : undefined}
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
          <Button variant="contained" color="secondary" onClick={onShowDeleteAccount}>
            Delete Account?
          </Button>
        </Box>
        <ViewTopPanelPortal>
          {showDelete ? (
            <SwitchBox
              mobileMode={!isDesktopWidth}
              component="form"
              onSubmit={onConfirmDeleteAccount}
              padding="2em"
              margin="-2em"
              marginBottom="1em"
              bgcolor="error.main"
              style={{
                top: '-2em',
                position: 'sticky',
                zIndex: 100,
                gap: '1em',
              }}
            >
              <Paper
                style={{
                  padding: '1em',
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="stretch">
                  <TextField
                    label="Confirm Email"
                    placeholder="Enter your email address to confirm deletion"
                    value={confirmingEmail}
                    onChange={onConfirmingEmailChange}
                    fullWidth
                  />
                </Box>
              </Paper>
              <Paper
                style={{
                  padding: '1em',
                }}
              >
                <Typography variant="caption" color="textPrimary">
                  WARNING: If you delete your account:
                  <ul>
                    <li>You will no longer be able to login.</li>
                    <li>Any thing you have listed or created will be deleted.</li>
                    <li>Your uploaded resume will be deleted.</li>
                    <li>You will no longer receive any newsletters.</li>
                    <li>Your profile will be deleted.</li>
                  </ul>
                </Typography>
              </Paper>
              &nbsp;&nbsp;
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button onClick={onCancelDelete} variant="contained" color="default">
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" variant="contained" color="secondary" disabled={!emailConfirmed}>
                  Confirm Delete
                </Button>
              </Box>
            </SwitchBox>
          ) : undefined}
          {viewDataChanged && !showDelete ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              component="form"
              onSubmit={onSubmit}
              padding="2em"
              margin="-2em"
              marginBottom="1em"
              bgcolor={!profileIsValid ? 'error.main' : 'info.main'}
              style={{
                top: '-2em',
                position: 'sticky',
                zIndex: 100,
              }}
            >
              <Typography variant="body1" color="textPrimary">
                {!profileIsValid ? <>Additional fields are required and must be valid. &nbsp;&nbsp;</> : undefined}
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Button onClick={onReset} variant="contained" color="default">
                  Reset
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary" disabled={!profileIsValid}>
                  Save
                </Button>
              </Box>
            </Box>
          ) : undefined}
        </ViewTopPanelPortal>
      </Box>
    </Box>
  );
};
