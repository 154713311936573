import React, { FC, ReactNode, useCallback } from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core';
import { shallowConvertNullToUndefined } from '../../Utils/DataUtils';
import { Profile } from '../../Types/Profile';
import styled from 'styled-components';
import { Visibility } from '@material-ui/icons';

const StyledListItem: typeof ListItem = styled(ListItem)``;
const StyledListItemText: typeof ListItemText = styled(ListItemText)``;

export type ProfileListItemProps = {
  profile?: Profile;
  onItemSelect?: (profileId: string) => void;
  children?: ReactNode;
};

export const ProfileListItem: FC<ProfileListItemProps> = ({ profile = {}, onItemSelect }) => {
  const { id = '', firstName = '', lastName = '', email = '' } = shallowConvertNullToUndefined(profile) as Profile;
  const onItemSelectInternal = useCallback(() => {
    if (id && onItemSelect) {
      onItemSelect(id);
    }
  }, [id, onItemSelect]);

  return (
    <StyledListItem component={Paper} button onClick={onItemSelectInternal}>
      <StyledListItemText primary={`${firstName} ${lastName}`} secondary={email} />
      <ListItemIcon>
        <ListItemSecondaryAction>
          <IconButton title="View Profile Details">
            <Visibility />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemIcon>
    </StyledListItem>
  );
};
