import React, { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

export type SwitchBoxProps = BoxProps & {
  mobileMode?: boolean;
};

export const SwitchBox: FC<SwitchBoxProps> = ({ mobileMode = false, children, ...boxProps }) => {
  return mobileMode ? (
    <Box
      flex="1 0 auto"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
      width="auto"
      style={{ gap: '1em' }}
      {...boxProps}
    >
      {children}
    </Box>
  ) : (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1em" {...boxProps}>
      {children}
    </Box>
  );
};
