import React, { FC } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const CandidateMenu: FC = () => {
  return (
    <MenuList>
      <MenuItem component={Link} to="/candidate/roles">
        Role Search
      </MenuItem>
      <MenuItem component={Link} to="/candidate/profile">
        Profile
      </MenuItem>
      <MenuItem component={Link} to="/candidate/positions">
        Positions
      </MenuItem>
    </MenuList>
  );
};
