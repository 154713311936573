import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { ItemViewController } from '../../Utils/ItemController';
import { WorkRole } from '../../Types/WorkRole';
import { ObjectController } from '../../Utils/ObjectController';
import { Backdrop, Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Position } from '../../Types/Position';
import { Address } from '../../Types/Address';
import { Autocomplete } from '@material-ui/lab';
import { WORK_ROLE_REGION_LIST, WorkRoleRegion } from './Constants';
import { Designation } from '../../Types/Designation';
import { useMediaWidth } from '../../Utils/MediaWidth';
import { MIN_DESKTOP_WIDTH } from '../../Constants';
import { SwitchBox } from '../../Utils/SwitchBox';

export type WorkRolesFormProps = {
  workRolesPending?: boolean;
  positionsPending?: boolean;
  designationOptionsPending?: boolean;
  editMode?: boolean;
  itemId?: string;
  workRole?: WorkRole;
  positionMap?: { [key: string]: Position };
  positions?: Position[];
  designationOptions?: Designation[];
  viewController?: ItemViewController<WorkRole>;
  onDone?: () => void;
};

export const WorkRolesForm: FC<WorkRolesFormProps> = ({
  workRolesPending = false,
  positionsPending = false,
  designationOptionsPending = false,
  editMode = false,
  itemId,
  workRole = {},
  positions = [],
  designationOptions = [],
  viewController,
  onDone,
}) => {
  const mediaWidth = useMediaWidth();
  const isDesktopWidth = useMemo(() => mediaWidth >= MIN_DESKTOP_WIDTH, [mediaWidth]);
  const pending = workRolesPending || positionsPending || designationOptionsPending;
  const regionInputRef = useRef<HTMLInputElement>();
  const ocCreator: ((itemId: string) => ObjectController<WorkRole>) | undefined = editMode
    ? viewController?.getChangingItemObjectController
    : viewController?.getCreatingItemObjectController;
  const objectController: ObjectController<WorkRole> | undefined =
    !!itemId && !!ocCreator ? ocCreator(itemId) : undefined;
  const formTitle = editMode ? 'Update Role' : 'Create Role';
  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (objectController) {
        objectController.onSubmit();
      }

      if (onDone) {
        onDone();
      }
    },
    [objectController, onDone]
  );
  const addressController = objectController?.getSubFieldObjectController<Address>('address');
  const {
    title = '',
    description = '',
    address,
    salary = 0,
    yearsOfExperience = 0,
    designations = [],
    callToActionEmail = '',
  } = workRole;
  const { streetAddressLine1 = '', streetAddressLine2 = '', city = '', state = '', zip = '' } = address || {};
  const canSave: boolean = !!(title && description && workRole?.position && workRole?.region?.length);
  const onPositionChange = useCallback(
    (_event, selectedPosition: Position | null = null) => {
      if (objectController) {
        objectController.getFieldChangeHandler('position')(selectedPosition);
      }
    },
    [objectController]
  );
  const onRegionChange = useCallback(
    (_event, selectedRegion: WorkRoleRegion | null) => {
      if (objectController) {
        const { id: regionId = '' } = selectedRegion || {};

        objectController.getFieldChangeHandler('region')(regionId);
      }
    },
    [objectController]
  );
  const onDesignationsChange = useCallback(
    (_event, selectedDesignations: Designation[]) => {
      if (objectController) {
        objectController.getFieldChangeHandler('designations')(selectedDesignations);
      }
    },
    [objectController]
  );

  useEffect(() => {
    if (regionInputRef.current) {
      regionInputRef.current.setAttribute('autocomplete', 'new-password');
    }
  }, []);

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <Typography variant="h5">{formTitle}</Typography>
      </Box>
      <br />
      <Box>
        <form onSubmit={onFormSubmit}>
          <Box>
            <Typography variant="subtitle1">Info</Typography>
            <SwitchBox mobileMode={!isDesktopWidth}>
              <TextField
                value={title}
                onChange={objectController?.getFieldInputChangeHandler('title')}
                label="Title"
                fullWidth
              />
              <TextField
                value={callToActionEmail}
                onChange={objectController?.getFieldInputChangeHandler('callToActionEmail')}
                label="Email you wish to use for candidate interest"
                fullWidth
                type="email"
              />
            </SwitchBox>
            <br />
            <SwitchBox mobileMode={!isDesktopWidth}>
              <Autocomplete
                options={positions}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                value={workRole?.position || null}
                onChange={onPositionChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Position" placeholder="Select a title..." />
                )}
                renderOption={(option, _state) => (
                  <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <Typography variant="body1">{option.name}</Typography>
                    <Typography variant="caption">{option.description}</Typography>
                  </Box>
                )}
              />
              <Autocomplete
                options={WORK_ROLE_REGION_LIST}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.id === value.id}
                value={WORK_ROLE_REGION_LIST.filter((r) => r.id === workRole?.region)[0] || null}
                onChange={onRegionChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Region"
                    placeholder="Select a focus area..."
                    inputRef={regionInputRef}
                  />
                )}
                renderOption={(option, _state) => (
                  <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <Typography variant="body1">{option.label}</Typography>
                    <Typography variant="caption">{option.focusArea}</Typography>
                  </Box>
                )}
              />
            </SwitchBox>
            <br />
            <SwitchBox mobileMode={!isDesktopWidth}>
              <TextField
                label="Salary ($/yr)"
                type="number"
                inputProps={{
                  min: 0,
                  step: 1,
                }}
                value={salary}
                onChange={objectController?.getFieldInputChangeHandler('salary')}
              />
              <Autocomplete
                multiple
                options={designationOptions}
                getOptionLabel={(option) => option.label || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                value={designations}
                onChange={onDesignationsChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Licenses/Designations"
                    placeholder="Add licenses and/or designations..."
                  />
                )}
                renderOption={(option, _state) => (
                  <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                    <Typography variant="body1">{option.label}</Typography>
                  </Box>
                )}
              />
            </SwitchBox>
            <br />
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="1em">
              <TextField
                label="Years Of Experience"
                type="number"
                inputProps={{
                  min: 0,
                  step: 1,
                }}
                value={yearsOfExperience}
                onChange={objectController?.getFieldInputChangeHandler('yearsOfExperience')}
              />
            </Box>
            <br />
            <Box>
              <TextField
                value={description}
                onChange={objectController?.getFieldInputChangeHandler('description')}
                label="Description"
                fullWidth
                multiline
                rows={6}
                variant="outlined"
              />
            </Box>
            <br />
            <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
              <Typography variant="subtitle1">Address</Typography>
              <TextField
                value={streetAddressLine1}
                onChange={addressController?.getFieldInputChangeHandler('streetAddressLine1')}
                label="Street Address Line 1"
                fullWidth
              />
              <br />
              <TextField
                value={streetAddressLine2}
                onChange={addressController?.getFieldInputChangeHandler('streetAddressLine2')}
                label="Street Address Line 2"
                fullWidth
              />
              <br />
              <SwitchBox mobileMode={!isDesktopWidth} gridTemplateColumns="1fr 1fr 1fr">
                <TextField value={city} onChange={addressController?.getFieldInputChangeHandler('city')} label="City" />
                <TextField
                  value={state}
                  onChange={addressController?.getFieldInputChangeHandler('state')}
                  label="State"
                />
                <TextField value={zip} onChange={addressController?.getFieldInputChangeHandler('zip')} label="Zip" />
              </SwitchBox>
            </Box>
            <br />
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Button onClick={onDone} color="default" variant="contained">
                Cancel
              </Button>
              &nbsp;
              <span title={!canSave ? 'Missing Required Fields' : undefined}>
                <Button type="submit" color="primary" variant="contained" disabled={!canSave}>
                  Save
                </Button>
              </span>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
