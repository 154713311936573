import React, { FC, useCallback } from 'react';
import { Profile } from '../Types/Profile';
import { ItemViewController } from '../Utils/ItemController';
import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { WorkRoleListItem } from './WorkRole/ListItem';
import { Remove } from '@material-ui/icons';

const NOOP = () => undefined;

export type SelectedWorkRolesExpandedMap = { [key: string]: boolean | undefined };
export type SelectedWorkRolesExpandedMapSetter = (value: SelectedWorkRolesExpandedMap) => void;

export type SelectedWorkRolesProps = {
  loading?: boolean;
  profile?: Profile;
  profileViewController?: ItemViewController<Profile>;
  expandedMap?: SelectedWorkRolesExpandedMap;
  setExpandedMap?: SelectedWorkRolesExpandedMapSetter;
};

export const SelectedWorkRoles: FC<SelectedWorkRolesProps> = ({
  loading = false,
  profile = {},
  profileViewController,
  expandedMap = {},
  setExpandedMap = NOOP,
}) => {
  const { id: profileId, selectedWorkRoles = [] } = profile;
  const profileObjectController = profileViewController?.getChangingItemObjectController(profileId as string);
  const onItemSelect = useCallback(
    (workRoleId: string) => {
      if (workRoleId) {
        const wasExpanded = expandedMap[workRoleId];

        setExpandedMap({
          ...expandedMap,
          [workRoleId]: !wasExpanded,
        });
      }
    },
    [expandedMap, setExpandedMap]
  );
  const onRemoveWorkRoleClick = useCallback(
    ({ currentTarget: { value } }) => {
      if (value && profileObjectController) {
        profileObjectController.getFieldChangeHandler('selectedWorkRoles')(
          selectedWorkRoles.filter((w) => w.id !== value)
        );
        profileObjectController.onSubmit();
      }
    },
    [profileObjectController, selectedWorkRoles]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Typography variant="h5">Selected Roles</Typography>
      <br />
      {loading ? (
        <Box minHeight="10em" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {selectedWorkRoles.map((wR, idx) => (
            <WorkRoleListItem
              key={`WorkRole:${idx}`}
              workRole={wR}
              expanded={!!expandedMap[wR.id as string]}
              onItemSelect={onItemSelect}
            >
              <IconButton value={wR.id} onClick={onRemoveWorkRoleClick}>
                <Remove />
              </IconButton>
            </WorkRoleListItem>
          ))}
        </>
      )}
    </Box>
  );
};
