import 'regenerator-runtime/runtime';
// @ts-ignore
import { hot } from 'react-hot-loader';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { AuthProvider, UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client';
import { Incarnate, IncarnateRoute, IncarnateRouter, IncarnateRouteSet, LifePod } from '@incarnate/react';
import { AppLayout, NavigationControls, Title, UserControls, View, ViewControls } from './App/Layout';
import { UserControls as HeaderUserControls } from './UserControls';
import { PATH_NAMES } from './Constants';
import { LoginStart } from './User/LoginStart';
import { TermsOfService } from './User/TermsOfService';
import { MediaWidthProvider } from './Utils/MediaWidth';
import { Candidate } from './App/Candidate';
import { Employer } from './App/Employer';
import { EmployerMenu } from './App/EmployerMenu';
import { CandidateMenu } from './App/CandidateMenu';
import { NavigationMenu } from './App/NavigationMenu';
import { GlobalStyle } from './App/GlobalStyle';
import { AppTitle } from './App/AppTitle';
import { EmployerServices } from './App/Dependencies/Services/EmployerServices';
import { UserServices } from './App/Dependencies/Services/UserServices';
import { AppEnvConfig } from './App/Types';
import { UserAuth } from './App/Dependencies/Auth/UserAuth';
import { getRoleDescriptor } from './Utils/RoleUtils';
import { AdminServices } from './App/Dependencies/Services/AdminServices';
import { automateRedirectToRestoreUrl } from './Utils/RedirectUtils';

// IMPORTANT: WWW Redirect.
if (window.location.host.indexOf('www.') === 0) {
  window.location.host = process.env.APP_DOMAIN as string;
}

const APP_ENV_CONFIG: AppEnvConfig = {
  api: {
    baseUrl: `https://${process.env.API_DOMAIN}`,
  },
  oauth: {
    clientId: process.env.USER_POOL_CLIENT_ID as string,
    domain: process.env.OAUTH_CONFIG_DOMAIN as string,
    scope: ['phone', 'email', 'profile', 'openid'].join(' '),
    redirectSignIn: `https://${process.env.APP_DOMAIN}/sign-in/complete`,
    redirectSignOut: `https://${process.env.APP_DOMAIN}`,
    responseType: 'code',
  },
  cookies: {
    domain: process.env.USER_POOL_CLIENT_COOKIE_DOMAIN as string,
    path: '/',
    expires: 1,
    secure: true,
    httpOnly: true,
  },
};
const USER_MANAGER_CONFIG = {
  loadUserInfo: true,
  authority: APP_ENV_CONFIG.oauth.domain,
  client_id: APP_ENV_CONFIG.oauth.clientId,
  redirect_uri: APP_ENV_CONFIG.oauth.redirectSignIn,
  response_type: APP_ENV_CONFIG.oauth.responseType,
  scope: APP_ENV_CONFIG.oauth.scope,
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: localStorage,
    prefix: 'ERC_USER_AUTH:',
  }),
};

declare const module: any;

const DEFAULT_SIGN_IN_INFO = {
  guestUser: true,
  profile: { email: 'Not Signed In' },
};

const App: FC = () => {
  const atLoginStart = window.location.pathname === PATH_NAMES.LOGIN_START;
  const atTOS = window.location.pathname === PATH_NAMES.VIEW_TERMS_OF_SERVICE;
  const displayControls = !atLoginStart && !atTOS;
  const [signInInfo, setSignInInfo] = useState<any>(DEFAULT_SIGN_IN_INFO);
  const setUser = useRef<Function>(() => true);
  const onSetUserSetterInitialized = useCallback((setter: Function) => (setUser.current = setter), []);
  const {
    guestUser,
    profile: { email, 'cognito:groups': authGroups = [] },
  } = signInInfo;
  const loading = guestUser && !atLoginStart && !atTOS;
  const { isAdmin, isEmployer } = getRoleDescriptor(authGroups);
  const authUserManager = useRef<UserManager>(new UserManager(USER_MANAGER_CONFIG));
  const onSignOut = useCallback(async () => {
    await authUserManager.current.removeUser();

    window.location.href = `https://${process.env.USER_POOL_CLIENT_DOMAIN}/logout?client_id=${APP_ENV_CONFIG.oauth.clientId}&logout_uri=${APP_ENV_CONFIG.oauth.redirectSignOut}`;
  }, []);
  const onSignIn = useCallback(
    (signInData): boolean => {
      if (signInData) {
        const { expires_at: expiresAt = 0 } = signInData;
        const nowMS = Math.ceil(new Date().getTime() / 1000);

        if (expiresAt <= nowMS) {
          onSignOut();
        } else {
          setSignInInfo(signInData as any);
          setUser.current(signInData);
          return automateRedirectToRestoreUrl();
        }
      } else if (!atLoginStart && !atTOS && !window.location.search) {
        // INFO: Automate redirection to intended URL after login.
        automateRedirectToRestoreUrl(true);

        window.location.pathname = PATH_NAMES.LOGIN_START;
      }

      return false;
    },
    [setSignInInfo, atLoginStart, atTOS, onSignOut]
  );
  const onAutoSignInComplete = useCallback(
    (signInData) => {
      const goToDefaultPath = !onSignIn(signInData);

      if (goToDefaultPath) {
        setTimeout(() => (window.location.href = '/'), 0);
      }
    },
    [onSignIn]
  );
  const onLogin = useCallback(async () => {
    const { url } = await authUserManager.current.createSigninRequest();

    window.location.href = url;
  }, []);
  const onViewTermsOfService = useCallback(() => {
    window.location.pathname = PATH_NAMES.VIEW_TERMS_OF_SERVICE;
  }, []);
  const onSignUp = useCallback(async () => {
    const { url = '' } = await authUserManager.current.createSigninRequest();

    // TRICKY: Use the login URL but replace `/oauth2/authorize` with `/signup`.
    window.location.href = url.replace(/\/oauth2\/authorize\/?\?/gm, () => '/signup?');
  }, []);

  useEffect(() => {
    authUserManager.current.getUser().then(onSignIn);
  }, [onSignIn]);

  return (
    <MediaWidthProvider>
      <IncarnateRouter>
        <Incarnate name="Auth">
          <UserAuth onSetterInitialized={onSetUserSetterInitialized} />
          <LifePod
            name="UserID"
            dependencies={{
              user: 'User',
            }}
            strict
            factory={(deps): string | undefined => {
              const {
                user: { profile: { sub } = {} } = {},
              }: {
                user: {
                  profile?: {
                    sub?: string;
                  };
                };
              } = deps as any;

              return sub;
            }}
          />
        </Incarnate>
        <Incarnate
          name="Services"
          shared={{
            Auth: 'Auth',
          }}
        >
          <AdminServices appEnvConfig={APP_ENV_CONFIG} />
          <EmployerServices appEnvConfig={APP_ENV_CONFIG} />
          <UserServices appEnvConfig={APP_ENV_CONFIG} />
        </Incarnate>
        <GlobalStyle />
        <IncarnateRoute subPath="/">
          <AuthProvider autoSignIn={false} userManager={authUserManager.current} onSignIn={onAutoSignInComplete}>
            <AppLayout viewOnly={!displayControls} loading={loading}>
              <Title>
                <AppTitle />
              </Title>
              <UserControls>
                <HeaderUserControls username={email} signedIn={!guestUser} onSignOut={onSignOut} />
              </UserControls>
              <NavigationControls>
                <NavigationMenu isAdmin={isAdmin} isEmployer={isEmployer} />
              </NavigationControls>
              <ViewControls>
                <IncarnateRouteSet defaultSubPath="candidate">
                  <IncarnateRoute subPath="candidate">
                    <CandidateMenu />
                  </IncarnateRoute>
                  <IncarnateRoute subPath="employer">
                    <EmployerMenu />
                  </IncarnateRoute>
                </IncarnateRouteSet>
              </ViewControls>
              <View>
                <IncarnateRouteSet defaultSubPath="candidate">
                  <IncarnateRoute subPath={PATH_NAMES.LOGIN_START}>
                    <LoginStart onLogin={onLogin} onSignUp={onViewTermsOfService} />
                  </IncarnateRoute>
                  <IncarnateRoute subPath={PATH_NAMES.VIEW_TERMS_OF_SERVICE}>
                    <TermsOfService onAccept={onSignUp} />
                  </IncarnateRoute>
                  <IncarnateRoute subPath="candidate">
                    <Candidate onAfterDeleteProfile={onSignOut} />
                  </IncarnateRoute>
                  <IncarnateRoute subPath="employer">
                    <Employer isAdmin={isAdmin} />
                  </IncarnateRoute>
                </IncarnateRouteSet>
              </View>
            </AppLayout>
          </AuthProvider>
        </IncarnateRoute>
      </IncarnateRouter>
    </MediaWidthProvider>
  );
};

export default hot(module)(App);
