import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #app-root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr;
    align-items: stretch;
    justify-content: stretch;
    font-family: sans-serif;
    overflow-x: hidden;
  }
`;
