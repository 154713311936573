import { ObjectController } from '../../Utils/ObjectController';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Position } from '../../Types/Position';

export type PositionFormProps = {
  formTitle: string;
  showCancelButton: boolean;
  formSubmitLabel: string;
  position?: Position;
  objectController?: ObjectController<Position>;
};

export function PositionForm({
  formTitle,
  showCancelButton = false,
  formSubmitLabel = 'Add',
  position,
  objectController,
}: PositionFormProps) {
  const { name = '', description = '' }: Position = position || {};
  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      objectController?.onSubmit();
    },
    [objectController]
  );

  return (
    <form onSubmit={onFormSubmit} style={{ flex: '1 1 auto' }}>
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <Typography variant="h5">{formTitle}</Typography>
        <TextField
          value={name}
          label="Position Name"
          fullWidth
          onChange={objectController?.getFieldInputChangeHandler('name')}
        />
        <TextField
          value={description}
          label="General Position Description (Including Basic Job Duties)"
          fullWidth
          multiline
          rows={4}
          onChange={objectController?.getFieldInputChangeHandler('description')}
        />
        <br />
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          {showCancelButton && (
            <>
              <Button variant="contained" onClick={objectController?.onCancel as any}>
                Cancel
              </Button>
              &nbsp;
            </>
          )}
          <Button type="submit" color="primary" variant="contained">
            {formSubmitLabel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
