import { AUTH_ROLES } from '@er-candidates/api/src/auth/Constants';

export const hasRole = (role: string, roleList: string[] = []): boolean => !!role && roleList.includes(role);

export type RoleDescriptor = {
  isAdmin: boolean;
  isEmployer: boolean;
};

export const getRoleDescriptor = (roleList: string[] = []): RoleDescriptor => {
  const isAdmin = hasRole(AUTH_ROLES.ADMIN, roleList);
  const isEmployer = isAdmin || hasRole(AUTH_ROLES.EMPLOYER, roleList);

  return {
    isAdmin,
    isEmployer,
  };
};
