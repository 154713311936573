import React, { FC, useMemo } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { LifePod } from '@incarnate/react';

export type NavigationMenuTabValue = {
  href: string;
  type: 'history' | 'link';
};

export type NavigationMenuTab = {
  value: NavigationMenuTabValue;
  label: string;
};

export const TAB_MAP: Record<string, NavigationMenuTab> = {
  HOME: {
    value: {
      href: '/',
      type: 'history',
    },
    label: 'Home',
  },
  EMPLOYER: {
    value: {
      href: 'employer',
      type: 'history',
    },
    label: 'Employer',
  },
  CAREER_RESOURCES: {
    value: {
      href: 'https://lead-advisor.com/career-resources',
      type: 'link',
    },
    label: 'Career Resources',
  },
  EMPLOYER_RESOURCES: {
    value: {
      href: 'https://lead-advisor.com/employer-resources',
      type: 'link',
    },
    label: 'Resources for Employers',
  },
  GETTING_STARTED: {
    value: {
      href: 'https://lead-advisor.com/gettingstarted',
      type: 'link',
    },
    label: 'Getting Started',
  },
  PUBLIC_JOB_BOARD: {
    value: {
      href: 'https://jobs.lead-advisor.com',
      type: 'link',
    },
    label: 'Public Job Board',
  },
};

export type NavigationMenuProps = {
  isAdmin?: boolean;
  isEmployer?: boolean;
};

export const NavigationMenu: FC<NavigationMenuProps> = ({ isAdmin = false, isEmployer = false }) => {
  const selectiveNavTabs = useMemo<NavigationMenuTab[]>(
    () =>
      isAdmin
        ? [TAB_MAP.HOME, TAB_MAP.EMPLOYER, TAB_MAP.EMPLOYER_RESOURCES, TAB_MAP.CAREER_RESOURCES]
        : isEmployer
        ? [TAB_MAP.HOME, TAB_MAP.EMPLOYER, TAB_MAP.EMPLOYER_RESOURCES]
        : [TAB_MAP.CAREER_RESOURCES],
    [isEmployer]
  );
  const navTabs = useMemo<NavigationMenuTab[]>(
    () => [...selectiveNavTabs, TAB_MAP.GETTING_STARTED, TAB_MAP.PUBLIC_JOB_BOARD],
    [selectiveNavTabs]
  );

  return (
    <LifePod
      dependencies={{
        routeProps: 'ROUTE_PROPS',
      }}
      factory={(deps: any = {}) => {
        const { routeProps: { location: { pathname = '' } = {}, history = undefined } = {} } = deps;

        return {
          value: pathname.includes('employer') ? TAB_MAP.EMPLOYER.value : TAB_MAP.HOME.value,
          onChange: (_e: any, { href, type }: NavigationMenuTabValue) => {
            if (type === 'history') {
              history?.push(href === '/' ? href : `/${href}`);
            } else {
              window.open(href, '_blank');
            }
          },
        };
      }}
    >
      <Tabs variant="scrollable">
        {navTabs.map((tab) => (
          <Tab key={tab.value.href} {...tab} />
        ))}
      </Tabs>
    </LifePod>
  );
};
