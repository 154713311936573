import { Profile } from '../../Types/Profile';

const numberIsValid = (number: number | string = ''): boolean => !isNaN(parseFloat(`${number}`));

const urlIsValid = (url: string = ''): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export type ManagedProfileFields =
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'selectedWorkRoles'
  | 'resumeDownloadUrl'
  | 'approved';

export type ProfileValidationResults = Record<keyof Omit<Profile, ManagedProfileFields>, boolean> & {
  resume: boolean;
};

export type ProfileIsValidConfig = {
  profile: Partial<Profile>;
  resumeExists: boolean;
  resumeFile?: File;
  overrideWith?: boolean;
  inverted?: boolean;
};

export const validateProfile = ({
  profile: {
    positionsOfInterest = [],
    regionsOfInterest = [],
    yearsOfExperience,
    youtubeVideoLink,
    linkedInLink,
    profileSummary = '',
    salary,
    designations = [],
  } = {},
  resumeExists,
  resumeFile,
  overrideWith,
  inverted = false,
}: ProfileIsValidConfig): ProfileValidationResults => {
  if (typeof overrideWith === 'boolean') {
    return {
      positionsOfInterest: overrideWith,
      regionsOfInterest: overrideWith,
      yearsOfExperience: overrideWith,
      youtubeVideoLink: overrideWith,
      linkedInLink: overrideWith,
      profileSummary: overrideWith,
      salary: overrideWith,
      designations: overrideWith,
      resume: overrideWith,
    };
  } else {
    const results: ProfileValidationResults = {
      positionsOfInterest: positionsOfInterest?.length > 0,
      regionsOfInterest: regionsOfInterest?.length > 0,
      yearsOfExperience: numberIsValid(yearsOfExperience) && parseFloat(`${yearsOfExperience}`) >= 1,
      youtubeVideoLink: !youtubeVideoLink || urlIsValid(youtubeVideoLink),
      linkedInLink: urlIsValid(linkedInLink),
      profileSummary: profileSummary?.length > 0,
      salary: numberIsValid(salary) && parseFloat(`${salary}`) > 0,
      designations: designations?.length > 0,
      resume: resumeExists || !!resumeFile,
    };

    return inverted
      ? Object.keys(results).reduce(
          (acc, k) => ({
            ...acc,
            [k]: !results[k as keyof ProfileValidationResults],
          }),
          {} as any
        )
      : results;
  }
};

export const getProfileIsValid = (validationResults: ProfileValidationResults): boolean => {
  return Object.values(validationResults).every((isValid) => isValid);
};
