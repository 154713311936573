import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { WorkRole } from '../../Types/WorkRole';
import { WORK_ROLE_REGION_MAP, WorkRoleRegion } from '../../Employer/WorkRoles/Constants';
import { getCleanWorkRoleRegionId } from '../../Employer/WorkRoles/Utils/WorkRoleRegionUtils';
import { getTextWithLinkNodes } from '../../Utils/ContentUtils';
import { shallowConvertNullToUndefined } from '../../Utils/DataUtils';
import NumberFormat from 'react-number-format';

export type WorkRoleListItemProps = {
  workRole?: WorkRole;
  expanded?: boolean;
  onItemSelect?: (workRoleId: string) => void;
  children?: ReactNode;
  onOpenEmployerProfileDetails?: (employerProfileId: string) => void;
  onShowMatchingProfiles?: (workRoleId: string) => void;
  standalone?: boolean;
};

export const WorkRoleListItem: FC<WorkRoleListItemProps> = ({
  workRole = {},
  expanded = false,
  onItemSelect,
  children,
  onOpenEmployerProfileDetails,
  onShowMatchingProfiles,
  standalone = false,
}) => {
  const {
    id: workRoleId,
    salary = 0,
    designations = [],
    yearsOfExperience = 0,
    description = '',
    employerId,
  } = shallowConvertNullToUndefined(workRole) as WorkRole;
  const descriptionWithLinks = useMemo(() => getTextWithLinkNodes(description), [description]);
  const { label: regionLabel = '', focusArea: regionFocusArea = '' }: WorkRoleRegion = ((workRole.region &&
    WORK_ROLE_REGION_MAP[getCleanWorkRoleRegionId(workRole.region)]) ||
    {}) as any;
  const onItemSelectInternal = useCallback(() => {
    if (workRole?.id && onItemSelect) {
      onItemSelect(workRole.id);
    }
  }, [workRole, onItemSelect]);
  const onOpenEmployerProfileDetailsInternal = useCallback(() => {
    if (employerId && onOpenEmployerProfileDetails) {
      onOpenEmployerProfileDetails(employerId);
    }
  }, [employerId, onOpenEmployerProfileDetails]);
  const onShowMatchingProfilesInternal = useCallback(() => {
    if (workRoleId && onShowMatchingProfiles) {
      onShowMatchingProfiles(workRoleId);
    }
  }, [onShowMatchingProfiles, workRoleId]);
  const BaseComponent = standalone ? Box : Accordion;
  const baseComponentProps = standalone
    ? {}
    : {
        expanded,
      };
  const summaryProps = standalone
    ? {
        variant: 'h3',
        gutterBottom: true,
      }
    : {
        onClick: onItemSelectInternal,
        expandIcon: <ExpandMore />,
      };
  const SummaryComponent = standalone ? (Typography as any) : AccordionSummary;

  return (
    <ListItem>
      <ListItemText>
        <BaseComponent {...baseComponentProps}>
          <SummaryComponent {...summaryProps}>{workRole.title}</SummaryComponent>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
              <Typography variant="subtitle1">Position: {workRole?.position?.name}</Typography>
              <br />
              <Typography variant="subtitle1">Region: {regionLabel}</Typography>
              <Typography variant="caption">{regionFocusArea}</Typography>
              <br />
              <Typography variant="subtitle1">
                Salary: <NumberFormat value={salary} displayType="text" thousandSeparator prefix="$" />
              </Typography>
              <br />
              <Typography variant="subtitle1">Years Of Experience: {yearsOfExperience}</Typography>
              <br />
              {designations?.length ? (
                <>
                  <Typography variant="subtitle1">Designations:</Typography>
                  {designations.map((d, i) => (
                    <Typography key={`Designation:${d.id}:${i}`} variant="body2">
                      {d.label}
                    </Typography>
                  ))}
                  <br />
                </>
              ) : undefined}
              {workRole?.address && (
                <>
                  <Typography variant="subtitle1">Address:</Typography>
                  <Typography variant="body2">
                    {workRole.address?.streetAddressLine1}
                    <br />
                    {workRole.address?.streetAddressLine2}
                    <br />
                    {workRole.address?.city}&nbsp;{workRole.address?.state}&nbsp;{workRole.address?.zip}
                  </Typography>
                  <br />
                </>
              )}
              <Typography variant="subtitle1">Description:</Typography>
              <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                {descriptionWithLinks}
              </Typography>
              {employerId && onOpenEmployerProfileDetails ? (
                <>
                  <br />
                  <Box
                    flex="1 0 auto"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Button onClick={onOpenEmployerProfileDetailsInternal}>View Employer Profile</Button>
                  </Box>
                </>
              ) : undefined}
              {onShowMatchingProfiles ? (
                <>
                  <br />
                  <Box
                    flex="1 0 auto"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Button onClick={onShowMatchingProfilesInternal}>Show Matching Profiles</Button>
                  </Box>
                </>
              ) : undefined}
            </Box>
          </AccordionDetails>
        </BaseComponent>
      </ListItemText>
      {children}
    </ListItem>
  );
};
