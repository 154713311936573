import React, { FC } from 'react';
import { LifePod } from '@incarnate/react';

export type UserAuthProps = {
  onSetterInitialized: (setter: Function) => void;
};

export const UserAuth: FC<UserAuthProps> = ({ onSetterInitialized }) => {
  return (
    <>
      <LifePod
        name="User"
        setters={{
          setSelf: 'User',
        }}
        factory={(deps) => {
          const {
            setSelf,
          }: {
            setSelf?: Function;
          } = deps;

          if (setSelf) {
            onSetterInitialized(setSelf);
          }
        }}
      />
    </>
  );
};
