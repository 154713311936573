import { IItemService } from './ItemController';
import { APIService } from './APIService';

export type DBServiceClientErrorOperation = 'create' | 'read' | 'update' | 'delete' | 'list';

export class DBServiceClientError<DataType> extends Error {
  static NAME = 'DB_SERVICE_CLIENT_ERROR';

  constructor(public operation: DBServiceClientErrorOperation, public error: any, public data?: DataType) {
    super(DBServiceClientError.NAME);
  }
}

export class DBServiceClient<DataType> extends APIService implements IItemService<DataType> {
  create = async (newItem: DataType): Promise<string> => {
    try {
      return (await this.axios.post('/create', [newItem])).data as string;
    } catch (error) {
      throw new DBServiceClientError<DataType>('create', error, newItem);
    }
  };

  read = async (itemId: string, relations: string[] = []): Promise<DataType> => {
    try {
      return (await this.axios.post('/read', [itemId, relations])).data as DataType;
    } catch (error) {
      throw new DBServiceClientError<string>('read', error, itemId);
    }
  };

  update = async (changedItem: DataType): Promise<boolean> => {
    try {
      return (await this.axios.post('/update', [changedItem])).data as boolean;
    } catch (error) {
      throw new DBServiceClientError<DataType>('update', error, changedItem);
    }
  };

  delete = async (itemId: string): Promise<boolean> => {
    try {
      return (await this.axios.post('/delete', [itemId])).data as boolean;
    } catch (error) {
      throw new DBServiceClientError<string>('delete', error, itemId);
    }
  };

  list = async (criteria?: DataType): Promise<DataType[]> => {
    try {
      return ((await this.axios.post('/list', [criteria])).data || []) as DataType[];
    } catch (error) {
      throw new DBServiceClientError<DataType>('list', error, criteria);
    }
  };
}
