import React, { FC } from 'react';
import styled from 'styled-components';
// @ts-ignore
import Logo from 'url:../Assets/Graphics/LogoWhite2020.png';

const APP_TITLE = 'Lead Advisor';

const LogoImage = styled.img`
  display: block;
`;

export const AppTitle: FC = () => {
  return (
    <>
      <a href="http://lead-advisor.com">
        <LogoImage alt={APP_TITLE} title={APP_TITLE} src={Logo} style={{ height: '1.75em', marginBottom: '0.25em' }} />
      </a>
    </>
  );
};
