import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { ItemViewController } from '../Utils/ItemController';
import { EmployerProfile } from '../Types/EmployerProfile';
import { FileViewController } from '../Utils/FileController';
import { ObjectController } from '../Utils/ObjectController';
import { shallowConvertNullToUndefined } from '../Utils/DataUtils';
import { SwitchBox } from '../Utils/SwitchBox';
import { FileUploadButton } from '../UIComponents/FileUploadButton';
import { useMediaWidth } from '../Utils/MediaWidth';
import { MIN_DESKTOP_WIDTH } from '../Constants';
import { ViewTopPanel } from '../App/Layout';
// @ts-ignore
import NoEmployerLogoYet from 'url:../Assets/Graphics/NoEmployerLogoYet.svg';
import styled from 'styled-components';

const { Portal: ViewTopPanelPortal } = ViewTopPanel;

const EmployerLogo = styled.img`
  flex: 0 0 auto;
  height: 4em;
`;

export type EmployerProfileViewProps = {
  pending?: boolean;
  originalEmployerProfile?: EmployerProfile;
  employerProfile?: EmployerProfile;
  viewController?: ItemViewController<EmployerProfile>;
  logoFileController?: FileViewController;
};

export const EmployerProfileView: FC<EmployerProfileViewProps> = ({
  pending = false,
  originalEmployerProfile,
  employerProfile = {},
  viewController,
  logoFileController,
}) => {
  const mediaWidth = useMediaWidth();
  const isDesktopWidth = useMemo(() => mediaWidth >= MIN_DESKTOP_WIDTH, [mediaWidth]);
  const {
    id: employerProfileId,
    name = '',
    logoUrl = '',
    youtubeVideoLink = '',
    websiteLink = '',
    description = '',
  } = shallowConvertNullToUndefined(employerProfile) as EmployerProfile;
  const objectController: ObjectController<EmployerProfile> | undefined = employerProfileId
    ? viewController?.getChangingItemObjectController(employerProfileId)
    : undefined;
  const logoFileInputRef = useRef<HTMLInputElement | undefined>();
  const [logoFile, setLogoFile] = useState<File | undefined>(undefined);
  const employerProfileChanged: boolean = originalEmployerProfile ? employerProfile !== originalEmployerProfile : false;
  const viewDataChanged: boolean = employerProfileChanged || !!logoFile;
  const onReset = useCallback(() => {
    if (employerProfileId && viewController) {
      viewController.initChangingItem(employerProfileId);
    }

    setLogoFile(undefined);
  }, [employerProfileId, viewController, setLogoFile]);
  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (employerProfileChanged) {
        objectController?.onSubmit();
      }

      if (logoFile) {
        await logoFileController?.uploadFile(logoFile, [logoFile.type]);
        setLogoFile(undefined);

        if (logoFileInputRef.current) {
          logoFileInputRef.current.value = '';
        }

        if (employerProfileId) {
          viewController?.loadItem(employerProfileId);
        }
      }
    },
    [
      objectController,
      logoFileController,
      logoFile,
      setLogoFile,
      employerProfileChanged,
      viewController,
      employerProfileId,
    ]
  );

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" gridGap="0.5em">
        <EmployerLogo src={!!logoUrl ? logoUrl : NoEmployerLogoYet} />
        <Typography variant="h5">Employer Profile</Typography>
      </Box>
      <br />
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        <SwitchBox mobileMode={!isDesktopWidth} gridTemplateColumns="2fr 1fr 1fr">
          <TextField
            label="Employer Name"
            placeholder="Example: Widgets & Wingdings LLC"
            type="text"
            value={name}
            onChange={objectController?.getFieldInputChangeHandler('name')}
          />
          <FileUploadButton
            uploaded={!!logoUrl}
            file={logoFile}
            onFileChange={setLogoFile}
            label="Logo"
            accept="image/*"
          />
        </SwitchBox>
        <br />
        <Divider
          variant="middle"
          style={{
            margin: '2em',
          }}
        />
        <br />
        <SwitchBox mobileMode={!isDesktopWidth}>
          <TextField
            label="YouTube Video Link"
            placeholder="Example: https://www.youtube.com/watch?v=[video-id]"
            type="text"
            value={youtubeVideoLink}
            onChange={objectController?.getFieldInputChangeHandler('youtubeVideoLink')}
          />
          <TextField
            label="Website"
            placeholder="Example: https://www.example.com"
            type="text"
            value={websiteLink}
            onChange={objectController?.getFieldInputChangeHandler('websiteLink')}
          />
        </SwitchBox>
        <br />
        <SwitchBox mobileMode={!isDesktopWidth} gridTemplateColumns="1fr">
          <TextField
            label="Description"
            placeholder="Write a few things about your culture, customers, employees or some interesting facts."
            fullWidth
            multiline
            rows={8}
            value={description}
            onChange={objectController?.getFieldInputChangeHandler('description')}
          />
        </SwitchBox>
        {viewDataChanged ? (
          <Button type="submit" variant="contained" color="primary" style={{ display: 'none' }}>
            Save
          </Button>
        ) : undefined}
        <ViewTopPanelPortal>
          {viewDataChanged ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              component="form"
              onSubmit={onSubmit}
              padding="2em"
              margin="-2em"
              marginBottom="1em"
              bgcolor="info.main"
              style={{
                top: '-2em',
                position: 'sticky',
                zIndex: 100,
              }}
            >
              <Button onClick={onReset} variant="contained" color="default">
                Reset
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          ) : undefined}
        </ViewTopPanelPortal>
      </Box>
    </Box>
  );
};
