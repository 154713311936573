import React, { FC, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  TextField,
  Typography,
} from '@material-ui/core';
import { Position } from '../Types/Position';
import { ExpandMore } from '@material-ui/icons';

export type PositionsViewProps = {
  userPositionsSearchTerm?: string;
  onSearchTermChange?: (value: string) => void;
  positions?: Position[];
  pending?: boolean;
};

export const PositionsView: FC<PositionsViewProps> = ({
  userPositionsSearchTerm = '',
  onSearchTermChange,
  positions = [],
  pending = false,
}) => {
  const onSearchChange = useCallback(
    ({ target: { value = '' } }) => {
      if (onSearchTermChange) {
        onSearchTermChange(value);
      }
    },
    [onSearchTermChange]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <Typography variant="h5">Positions</Typography>
      <br />
      <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
        {pending ? (
          <Box minHeight="10em" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TextField
              label="Search"
              placeholder="Enter some search terms..."
              type="search"
              value={userPositionsSearchTerm}
              onChange={onSearchChange}
            />
            <br />
            <List>
              {positions.map((pos, index) => (
                <Accordion key={`Position:${index}`}>
                  <AccordionSummary expandIcon={<ExpandMore />}>{pos.name}</AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">{pos.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </>
        )}
      </Box>
    </Box>
  );
};
