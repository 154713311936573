import Axios, { AxiosInstance } from 'axios';

export class APIService {
  axios: AxiosInstance;

  constructor(baseUrl: string, idOrAccessToken: string) {
    this.axios = Axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${idOrAccessToken}`,
      },
      withCredentials: true,
    });
  }
}
