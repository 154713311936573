import { APIService } from '../Utils/APIService';
import { uploadFileToS3SignedUrl } from '../Utils/FileUtils';

export enum FileServiceErrorType {
  GET_UPLOAD_URL_FAILED = 'GET_UPLOAD_URL_FAILED',
  UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED',
  FILE_EXISTS_FAILED = 'FILE_EXISTS_FAILED',
}

export class FileServiceError extends Error {
  static NAME = 'FILE_SERVICE_ERROR';

  constructor(public type: FileServiceErrorType, public error: any) {
    super(FileServiceError.NAME);
  }
}

export class FileService extends APIService {
  constructor(
    baseUrl: string,
    idOrAccessToken: string,
    public getUploadUrlPath: string = '/get-upload-url',
    public fileExistsPath: string = '/file-exists'
  ) {
    super(baseUrl, idOrAccessToken);
  }

  getUploadUrl = async (...args: any[]): Promise<string> => {
    try {
      return (await this.axios.post(this.getUploadUrlPath, args)).data as string;
    } catch (error) {
      throw new FileServiceError(FileServiceErrorType.GET_UPLOAD_URL_FAILED, error);
    }
  };

  uploadFile = async (fileToUpload: File, getUploadUrlArgs: any[] = []): Promise<void> => {
    try {
      await uploadFileToS3SignedUrl(await this.getUploadUrl(...getUploadUrlArgs), fileToUpload);
    } catch (error) {
      throw new FileServiceError(FileServiceErrorType.UPLOAD_FILE_FAILED, error);
    }
  };

  fileExists = async (): Promise<boolean> => {
    try {
      const { data: resumeExists = false }: { data?: boolean } = await this.axios.post(this.fileExistsPath, []);

      return resumeExists || false;
    } catch (error) {
      throw new FileServiceError(FileServiceErrorType.FILE_EXISTS_FAILED, error);
    }
  };
}
