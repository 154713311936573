import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
// @ts-ignore
import LogoDarkBlue2020 from 'url:../Assets/Graphics/LogoDarkBlue2020.svg';
// @ts-ignore
import TOSContent from 'url:./TermsOfService/content.pdf';

const Base = styled.div`
  background-color: #efefef;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  width: 100%;
  height: 100%;
`;
const StyledCard = styled(Card)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  width: auto;
  height: auto;

  margin: 2em;
`;
const TermsContent = styled(CardContent)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  overflow: auto;

  padding: 0;
  margin: 1em;
`;
const TOSFrame = styled.iframe`
  flex: 1 0 auto;

  border: 0.05em solid #000000;

  width: auto;
  height: auto;
`;
const ButtonContent = styled(CardContent)`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const StyledLogo = styled.img`
  width: 20%;
`;

export type TermsOfServiceProps = {
  onAccept: () => void;
};

export const TermsOfService: FC<TermsOfServiceProps> = ({ onAccept }) => {
  return (
    <Base>
      <StyledCard>
        <CardHeader title={<StyledLogo title="Lead-Advisor" src={LogoDarkBlue2020} />} />
        <CardHeader
          title="Terms Of Service"
          subheader="You must accept these terms in order to use the Lead-Advisor application."
        />
        <TermsContent>
          <TOSFrame src={`${TOSContent}#toolbar=0&navpanes=0&scrollbar=0`} />
        </TermsContent>
        <ButtonContent>
          <Button variant="contained" size="large" color="primary" onClick={onAccept}>
            Accept
          </Button>
        </ButtonContent>
      </StyledCard>
    </Base>
  );
};
