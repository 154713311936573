import React, { FC } from 'react';
import { ItemController, ItemViewController } from '../Utils/ItemController';
import { Profile } from '../Types/Profile';
import { Position } from '../Types/Position';
import { Designation } from '../Types/Designation';
import { IncarnateRoute, IncarnateRouteSet, ItemQueueProcessor, LifePod } from '@incarnate/react';
import { Positions } from '../Employer/Positions';
import { WorkRole } from '../Types/WorkRole';
import { WorkRoles } from '../Employer/WorkRoles';
import { WorkRolesForm, WorkRolesFormProps } from '../Employer/WorkRoles/Form';
import { ProfileSearch, ProfileSearchProps } from '../Employer/ProfileSearch';
import { ProfileDetails, ProfileDetailsProps } from '../Employer/ProfileDetails';
import { EmployerProfileView, EmployerProfileViewProps } from '../Employer/EmployerProfileView';
import { FileController } from '../Utils/FileController';
import { EmployerProfile } from '../Types/EmployerProfile';
import { ObjectController } from '../Utils/ObjectController';

const PROFILE_DETAILS_ITEM_RELATIONS = ['positionsOfInterest', 'designations'];

export type EmployerProps = {
  isAdmin?: boolean;
};

export const Employer: FC<EmployerProps> = ({ isAdmin = false }) => {
  return (
    <>
      <ItemController
        name="AdminProfileController"
        shared={{
          ItemService: 'Services.AdminProfile',
        }}
      />
      <ItemController
        name="EmployerEmployerProfileController"
        shared={{
          ItemService: 'Services.EmployerEmployerProfile',
        }}
      />
      <LifePod
        name="EmployerUserID"
        dependencies={{
          userId: 'Auth.UserID',
          viewController: 'EmployerEmployerProfileController.ViewController',
        }}
        strict
        factory={(deps): string | undefined => {
          const {
            userId,
            viewController,
          }: {
            userId?: string;
            viewController: ItemViewController<EmployerProfile>;
          } = deps as any;

          if (userId) {
            viewController.loadItem(userId);
          }

          return userId;
        }}
      />
      <LifePod
        name="ExistingEmployerEmployerProfile"
        dependencies={{
          userId: 'EmployerUserID',
          employerProfileMap: 'EmployerEmployerProfileController.Existing',
          viewController: 'EmployerEmployerProfileController.ViewController',
        }}
        strict
        factory={(deps): EmployerProfile | undefined => {
          const {
            userId,
            employerProfileMap,
            viewController,
          }: {
            userId?: string;
            employerProfileMap?: { [key: string]: EmployerProfile };
            viewController: ItemViewController<EmployerProfile>;
          } = deps as any;

          if (userId && employerProfileMap) {
            const employerProfile: EmployerProfile | undefined = employerProfileMap[userId];

            if (employerProfile) {
              viewController.initChangingItem(userId);

              return employerProfile;
            }
          }
        }}
      />
      <ItemController<Profile>
        name="EmployerProfileController"
        shared={{
          ItemService: 'Services.EmployerProfile',
        }}
        debounceListUpdateMS={800}
      />
      {isAdmin ? (
        <>
          <LifePod name="AdminEmployerProfileSync" factory={() => (i) => i} />
          <ItemQueueProcessor
            shared={{
              InputMap: 'AdminProfileController.Existing',
              OutputMap: 'EmployerProfileController.Existing',
              ErrorMap: 'EmployerProfileController.Errors.Update',
              ItemProcessor: 'AdminEmployerProfileSync',
            }}
          />
        </>
      ) : undefined}
      <ItemController<Profile>
        name="EmployerWorkRoleProfileController"
        shared={{
          ItemService: 'Services.EmployerWorkRoleProfile',
        }}
        debounceListUpdateMS={800}
      />
      <ItemController<Position>
        name="PositionsController"
        shared={{
          ItemService: 'Services.EmployerPosition',
        }}
        preloadList
      />
      <ItemController<Designation>
        name="DesignationsController"
        shared={{
          ItemService: 'Services.EmployerDesignation',
        }}
        preloadList
      />
      <LifePod
        name="EmployerToUserPositionSynchronizer"
        dependencies={{
          existingEmployerPositionMap: 'PositionsController.Existing',
        }}
        setters={{
          setExistingUserPositions: 'UserPositionController.Existing',
        }}
        factory={(deps) => {
          const {
            existingEmployerPositionMap = {},
            setExistingUserPositions,
          }: {
            existingEmployerPositionMap: { [key: string]: Position };
            setExistingUserPositions: (value: Position, id: string) => void;
          } = deps as any;

          if (setExistingUserPositions) {
            for (const id in existingEmployerPositionMap) {
              setExistingUserPositions(existingEmployerPositionMap[id], id);
            }
          }
        }}
      />
      <IncarnateRouteSet defaultSubPath="positions">
        <IncarnateRoute subPath="positions">
          <LifePod
            dependencies={{
              pending: 'PositionsController.Pending.Any',
              positions: 'PositionsController.ExistingItemList',
              creatingPosition: 'PositionsController.LatestCreatingItem',
              changingPosition: 'PositionsController.LatestChangingItem',
              viewController: 'PositionsController.ViewController',
            }}
            mapToProps={(p) => p}
          >
            <Positions hideForm={!isAdmin} />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="roles">
          <ItemController<WorkRole>
            name="WorkRoleController"
            shared={{
              ItemService: 'Services.EmployerWorkRole',
            }}
            preloadList
          />
          <IncarnateRouteSet defaultSubPath="/">
            <IncarnateRoute subPath="/" exact>
              <LifePod
                name="WorkRoleNavigationController"
                dependencies={{
                  routeProps: 'ROUTE_PROPS',
                }}
                factory={({ routeProps }) => {
                  const { history } = routeProps || {};

                  return {
                    onCreateWorkRole: (itemId: string) => history?.push(`./create/${itemId}`),
                    onEditWorkRole: (itemId: string) => history?.push(`./edit/${itemId}`),
                  };
                }}
              />
              <LifePod
                dependencies={{
                  workRolesPending: 'WorkRoleController.Pending.Any',
                  positionsPending: 'PositionsController.Pending.Any',
                  workRoleNavigationController: 'WorkRoleNavigationController',
                  workRoles: 'WorkRoleController.ExistingItemList',
                  viewController: 'WorkRoleController.ViewController',
                  selectedWorkRoleMap: 'WorkRoleController.SelectedExisting',
                  history: 'ROUTE_PROPS.history',
                }}
                mapToProps={({ workRoleNavigationController, history, ...other }) => ({
                  ...other,
                  ...workRoleNavigationController,
                  onShowMatchingProfiles: (workRoleId: string) =>
                    history.push(`../work-role-profile-search/${workRoleId}`),
                })}
              >
                <WorkRoles />
              </LifePod>
            </IncarnateRoute>
            <IncarnateRoute subPath="create/:workRoleId">
              <LifePod
                name="CreatingWorkRoleId"
                dependencies={{ routeProps: 'ROUTE_PROPS' }}
                factory={({ routeProps }) => routeProps?.params?.workRoleId}
              />
              <LifePod
                dependencies={{
                  workRolesPending: 'WorkRoleController.Pending.Any',
                  positionsPending: 'PositionsController.Pending.Any',
                  designationOptionsPending: 'DesignationsController.Pending.Any',
                  itemId: 'CreatingWorkRoleId',
                  existingWorkRoleMap: 'WorkRoleController.Creating',
                  viewController: 'WorkRoleController.ViewController',
                  routeProps: 'ROUTE_PROPS',
                  positionMap: 'PositionsController.Existing',
                  positions: 'PositionsController.ExistingItemList',
                  designationOptions: 'DesignationsController.ExistingItemList',
                }}
                mapToProps={(deps): WorkRolesFormProps => {
                  const {
                    workRolesPending = false,
                    positionsPending = false,
                    designationOptionsPending = false,
                    itemId,
                    existingWorkRoleMap = {},
                    viewController,
                    routeProps: { history } = {},
                    positionMap = {},
                    positions = [],
                    designationOptions = [],
                  }: {
                    workRolesPending?: boolean;
                    positionsPending?: boolean;
                    designationOptionsPending?: boolean;
                    itemId?: string;
                    existingWorkRoleMap?: { [key: string]: WorkRole };
                    viewController?: ItemViewController<WorkRole>;
                    routeProps?: { history?: any };
                    positionMap?: { [key: string]: Position };
                    positions?: Position[];
                    designationOptions?: Designation[];
                  } = deps as any;

                  return {
                    workRolesPending,
                    positionsPending,
                    designationOptionsPending,
                    itemId,
                    workRole: !!itemId ? existingWorkRoleMap[itemId] : undefined,
                    positionMap,
                    positions,
                    designationOptions,
                    viewController,
                    onDone: () => history.push('../'),
                  };
                }}
              >
                <WorkRolesForm editMode={false} />
              </LifePod>
            </IncarnateRoute>
            <IncarnateRoute subPath="edit/:workRoleId">
              <LifePod
                name="ChangingWorkRoleId"
                dependencies={{ routeProps: 'ROUTE_PROPS' }}
                factory={({ routeProps }) => routeProps?.params?.workRoleId}
              />
              <LifePod
                name="ChangingWorkRoleWatcher"
                dependencies={{
                  workRoleId: 'ChangingWorkRoleId',
                  existingWorkRoleMap: 'WorkRoleController.Existing',
                  changingWorkRoleMap: 'WorkRoleController.Changing',
                  viewController: 'WorkRoleController.ViewController',
                }}
                factory={async (deps) => {
                  const {
                    workRoleId,
                    existingWorkRoleMap = {},
                    changingWorkRoleMap = {},
                    viewController,
                  }: {
                    workRoleId?: string;
                    existingWorkRoleMap?: { [key: string]: WorkRole };
                    changingWorkRoleMap?: { [key: string]: WorkRole };
                    viewController?: ItemViewController<WorkRole>;
                  } = deps;

                  if (workRoleId && !existingWorkRoleMap[workRoleId] && viewController) {
                    await viewController.loadItem(workRoleId);
                  } else if (workRoleId && !changingWorkRoleMap[workRoleId] && viewController) {
                    viewController.initChangingItem(workRoleId);
                  }
                }}
              />
              <LifePod
                dependencies={{
                  workRolesPending: 'WorkRoleController.Pending.Any',
                  positionsPending: 'PositionsController.Pending.Any',
                  designationOptionsPending: 'DesignationsController.Pending.Any',
                  itemId: 'ChangingWorkRoleId',
                  existingWorkRoleMap: 'WorkRoleController.Changing',
                  viewController: 'WorkRoleController.ViewController',
                  routeProps: 'ROUTE_PROPS',
                  positions: 'PositionsController.ExistingItemList',
                  designationOptions: 'DesignationsController.ExistingItemList',
                }}
                mapToProps={(deps): WorkRolesFormProps => {
                  const {
                    workRolesPending = false,
                    positionsPending = false,
                    designationOptionsPending = false,
                    itemId,
                    existingWorkRoleMap = {},
                    viewController,
                    routeProps: { history } = {},
                    positions = [],
                    designationOptions = [],
                  }: {
                    workRolesPending?: boolean;
                    positionsPending?: boolean;
                    designationOptionsPending?: boolean;
                    itemId?: string;
                    existingWorkRoleMap?: { [key: string]: WorkRole };
                    viewController?: ItemViewController<WorkRole>;
                    routeProps?: { history?: any };
                    positions?: Position[];
                    designationOptions?: Designation[];
                  } = deps as any;

                  return {
                    workRolesPending,
                    positionsPending,
                    designationOptionsPending,
                    itemId,
                    workRole: !!itemId ? existingWorkRoleMap[itemId] : undefined,
                    positions,
                    designationOptions,
                    viewController,
                    onDone: () => history.push('../'),
                  };
                }}
              >
                <WorkRolesForm editMode />
              </LifePod>
            </IncarnateRoute>
          </IncarnateRouteSet>
        </IncarnateRoute>
        <IncarnateRoute subPath="profile-search">
          <LifePod
            dependencies={{
              profileQuery: 'EmployerProfileController.ListParams',
              profileList: 'EmployerProfileController.ExistingItemList',
              pending: 'EmployerProfileController.Pending.Any',
              viewController: 'EmployerProfileController.ViewController',
              history: 'ROUTE_PROPS.history',
              positionsPending: 'PositionsController.Pending.Any',
              designationOptionsPending: 'DesignationsController.Pending.Any',
              positions: 'PositionsController.ExistingItemList',
              designationOptions: 'DesignationsController.ExistingItemList',
            }}
            mapToProps={({ history, ...p }): ProfileSearchProps => ({
              ...p,
              onOpenProfileDetails: (profileId: string) => history.push(`profile-details/${profileId}`),
            })}
          >
            <ProfileSearch />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="work-role-profile-search/:workRoleId">
          <LifePod
            name="EmployerWorkRoleProfileLoader"
            dependencies={{
              routeProps: 'ROUTE_PROPS',
              viewController: 'EmployerWorkRoleProfileController.ViewController',
            }}
            strict
            factory={({ routeProps, viewController }) => {
              const { params: { workRoleId = undefined } = {} } = routeProps || {};

              if (workRoleId && viewController) {
                viewController.onListParamsChange({ id: workRoleId });
              }
            }}
          />
          <LifePod
            dependencies={{
              profileList: 'EmployerWorkRoleProfileController.ExistingItemList',
              pending: 'EmployerWorkRoleProfileController.Pending.Any',
              history: 'ROUTE_PROPS.history',
            }}
            mapToProps={({ history, ...p }): ProfileSearchProps => ({
              ...p,
              onOpenProfileDetails: (profileId: string) => history.push(`../profile-details/${profileId}`),
            })}
          >
            <ProfileSearch hideSearchControls />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="profile-details/:profileId">
          <LifePod
            name="ProfileDetailsID"
            dependencies={{
              routeProps: 'ROUTE_PROPS',
            }}
            factory={({ routeProps: { params: { profileId = undefined } = {} } = {} }) => profileId}
          />
          <LifePod
            name="ProfileDetailsLoader"
            dependencies={{
              profileId: 'ProfileDetailsID',
              viewController: 'EmployerProfileController.ViewController',
              existingItemMap: 'EmployerProfileController.Existing',
              existingItemRelationsMap: 'EmployerProfileController.ItemRelations',
            }}
            strict
            factory={({ profileId = '', viewController, existingItemMap = {}, existingItemRelationsMap = {} }) => {
              if (viewController && profileId) {
                const vC = viewController as ItemViewController<Profile>;
                const existingItem = existingItemMap[profileId];
                const existingItemRelations: string[] = existingItemRelationsMap[profileId] || [];
                const itemRelationsWereRequested: boolean = PROFILE_DETAILS_ITEM_RELATIONS.reduce(
                  (acc, r) => acc && existingItemRelations.includes(r),
                  true
                );

                if (existingItem && itemRelationsWereRequested) {
                  vC.initChangingItem(profileId);
                } else {
                  // TRICKY: Delay loading the Profile until after the view has rendered once.
                  setTimeout(() => {
                    vC.loadItem(profileId, PROFILE_DETAILS_ITEM_RELATIONS);
                  }, 0);
                }
              }
            }}
          />
          <LifePod
            name="ProfileChangeHandler"
            dependencies={{
              employerProfileViewController: 'EmployerProfileController.ViewController',
              adminProfileViewController: 'AdminProfileController.ViewController',
            }}
            factory={({ employerProfileViewController, adminProfileViewController }) => {
              if (adminProfileViewController && employerProfileViewController) {
                const typedEmployerProfileViewController = employerProfileViewController as ItemViewController<Profile>;
                const typedAdminProfileViewController = adminProfileViewController as ItemViewController<Profile>;

                return (newProfile: Profile) => {
                  const { id: profileId }: Partial<Profile> = newProfile || {};

                  if (profileId) {
                    const employerProfileObjectController: ObjectController<Profile> =
                      typedEmployerProfileViewController.getChangingItemObjectController(profileId);
                    const adminProfileObjectController: ObjectController<Profile> =
                      typedAdminProfileViewController.getChangingItemObjectController(profileId);

                    // Update the Profile in memory.
                    employerProfileObjectController.onValueChange(newProfile);

                    // Submit the profile to the API.
                    adminProfileObjectController.onValueChange(newProfile);
                    adminProfileObjectController.onSubmit();
                  }
                };
              }
            }}
          />
          <LifePod
            dependencies={
              {
                profile: 'EmployerProfileController.LatestChangingItem',
                pending: 'EmployerProfileController.Pending.Any',
                onProfileChange: 'ProfileChangeHandler',
              } as Record<keyof ProfileDetailsProps, string>
            }
            mapToProps={(p) => p}
          >
            <ProfileDetails isAdmin={isAdmin} />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="employer-profile">
          <FileController
            name="EmployerLogoController"
            shared={{
              FileService: 'Services.EmployerLogo',
            }}
          />
          <LifePod
            name="EmployerEmployerProfileDataPending"
            dependencies={{
              resumePending: 'EmployerLogoController.Activity',
              employerProfilePending: 'EmployerEmployerProfileController.Pending.Any',
            }}
            factory={(deps) => Object.keys({ ...deps }).reduce((acc, p) => acc || deps[p], false)}
          />
          <LifePod
            dependencies={
              {
                pending: 'EmployerEmployerProfileDataPending',
                originalEmployerProfile: 'ExistingEmployerEmployerProfile',
                employerProfile: 'EmployerEmployerProfileController.LatestChangingItem',
                viewController: 'EmployerEmployerProfileController.ViewController',
                logoFileController: 'EmployerLogoController.ViewController',
              } as Record<keyof EmployerProfileViewProps, string>
            }
            mapToProps={(p): EmployerProfileViewProps => p}
          >
            <EmployerProfileView />
          </LifePod>
        </IncarnateRoute>
      </IncarnateRouteSet>
    </>
  );
};
