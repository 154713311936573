import React, { ReactNode } from 'react';
import Autolinker, { Match } from 'autolinker';

export const getTextWithLinkNodes = (text = ''): ReactNode[] => {
  const urlMatches: Match[] = Autolinker.parse(text, { urls: true });
  const nodes: ReactNode[] = [];
  const lastTextIndex = text.length - 1;

  let lastOffset = 0;

  for (const u of urlMatches) {
    const urlText = u.getMatchedText();
    const offset = u.getOffset();
    const precedingPlainText = text.slice(lastOffset, offset);

    nodes.push(precedingPlainText);
    nodes.push(
      <a key={`URL:${urlText}`} href={urlText} target="_blank" rel="noreferrer">
        {urlText}
      </a>
    );

    lastOffset += offset + urlText.length;
  }

  if (lastOffset < lastTextIndex) {
    const finalPlainText = text.slice(lastOffset, lastTextIndex + 1);

    nodes.push(finalPlainText);
  }

  return nodes;
};
