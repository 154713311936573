import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Box, Button, SvgIcon, Typography, useTheme } from '@material-ui/core';
import { CloudDone, Error } from '@material-ui/icons';

export type FileUploadButtonProps = {
  uploaded: boolean;
  file?: File;
  onFileChange?: (file: File) => void;
  label?: string;
  accept?: string;
  error?: boolean;
};

export const FileUploadButton: FC<FileUploadButtonProps> = ({
  uploaded = false,
  file,
  onFileChange,
  label = 'File',
  accept,
  error,
}) => {
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement | undefined>();
  const onFilesChangeInternal = useCallback(
    ({ currentTarget: { files } }) => {
      if (onFileChange && files && files[0]) {
        onFileChange(files[0]);
      }
    },
    [onFileChange]
  );

  useEffect(() => {
    if (fileInputRef.current && typeof file === 'undefined') {
      fileInputRef.current.value = '';
    }
  }, [file]);

  return (
    <>
      {uploaded ? (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <SvgIcon
            style={{
              color: error ? theme?.palette?.error?.main || 'darkred' : theme?.palette?.success?.main || 'darkgreen',
            }}
          >
            {error ? <Error /> : <CloudDone />}
          </SvgIcon>
          &nbsp;
          <Typography variant="body1" color="textSecondary">
            {label} uploaded
          </Typography>
        </Box>
      ) : (
        <Box>
          {error ? (
            <SvgIcon
              style={{
                color: theme?.palette?.error?.main || 'darkred',
              }}
            >
              <Error />
            </SvgIcon>
          ) : undefined}
        </Box>
      )}
      <Button variant="contained" component="label">
        {!file ? (uploaded ? `Upload A New ${label}` : `Upload Your ${label}`) : file.name}
        <input
          type="file"
          accept={accept}
          style={{ display: 'none' }}
          onChange={onFilesChangeInternal}
          {...({
            // WORKAROUND: TypeScript has some trouble knowing that `ref` is valid,
            // so we simply bypass it by spreading an `any` object with `ref` on it.
            ref: fileInputRef,
          } as any)}
        />
      </Button>
    </>
  );
};
