import React, { FC } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const EmployerMenu: FC = () => {
  return (
    <MenuList>
      <MenuItem component={Link} to="/employer/positions">
        Positions
      </MenuItem>
      <MenuItem component={Link} to="/employer/roles">
        Roles
      </MenuItem>
      <MenuItem component={Link} to="/employer/profile-search">
        Profile Search
      </MenuItem>
      <MenuItem component={Link} to="/employer/employer-profile">
        Employer Profile
      </MenuItem>
    </MenuList>
  );
};
