import React, { createContext, FC, useContext, useEffect, useState } from 'react';

const getMediaWidth = () => window.innerWidth;

const MediaWidthContext = createContext<number>(getMediaWidth());
const { Provider } = MediaWidthContext;

export const MediaWidthProvider: FC = ({ children }) => {
  const [mediaWidth, setMediaWidth] = useState<number>(getMediaWidth());

  useEffect(() => {
    const onResize = () => {
      setMediaWidth(getMediaWidth());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setMediaWidth]);

  return <Provider value={mediaWidth}>{children}</Provider>;
};

export const useMediaWidth = () => useContext<number>(MediaWidthContext);
