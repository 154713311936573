import React, { FC, useCallback, useEffect } from 'react';
import { WorkRole } from '../Types/WorkRole';
import { ItemViewController } from '../Utils/ItemController';
import { Controls, List as ListPlacement, WorkRolesListLayout } from './WorkRoles/ListLayout';
import { Backdrop, Box, Button, CircularProgress, IconButton, List, ListItemIcon } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { WorkRoleListItem } from '../User/WorkRole/ListItem';

export type WorkRolesProps = {
  workRolesPending?: boolean;
  positionsPending?: boolean;
  workRoles?: WorkRole[];
  viewController?: ItemViewController<WorkRole>;
  onCreateWorkRole?: (itemId: string) => void;
  onEditWorkRole?: (itemId: string) => void;
  selectedWorkRoleMap?: { [key: string]: WorkRole };
  onShowMatchingProfiles?: (workRoleId: string) => void;
};

export const WorkRoles: FC<WorkRolesProps> = ({
  workRolesPending = false,
  positionsPending = false,
  workRoles = [],
  viewController,
  onCreateWorkRole,
  onEditWorkRole,
  selectedWorkRoleMap,
  onShowMatchingProfiles,
}) => {
  const pending = workRolesPending || positionsPending;
  const onCreateWorkRoleClick = useCallback(() => {
    if (viewController && onCreateWorkRole) {
      onCreateWorkRole(viewController.initCreatingItem());
    }
  }, [viewController, onCreateWorkRole]);
  const onEditWorkRoleClick = useCallback(
    ({ currentTarget: { value } }) => {
      if (viewController && onEditWorkRole) {
        onEditWorkRole(viewController.initChangingItem(value as string));
      }
    },
    [viewController, onEditWorkRole]
  );
  const onDeleteWorkRoleClick = useCallback(
    ({ currentTarget: { value } }) => {
      if (viewController) {
        viewController.onDeleteItem(value as string);
      }
    },
    [viewController]
  );
  const onItemSelect = useCallback(
    (workRoleId: string) => {
      if (workRoleId && viewController) {
        viewController.toggleSelectItemById(workRoleId);
      }
    },
    [viewController]
  );
  const onShowMatchingProfilesInternal = useCallback(
    (workRoleId: string) => {
      if (onShowMatchingProfiles) {
        onShowMatchingProfiles(workRoleId);
      }
    },
    [onShowMatchingProfiles]
  );

  useEffect(() => {
    if (viewController) {
      viewController.onListParamsChange({});
    }
  }, [viewController]);

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WorkRolesListLayout>
        <Controls>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Button onClick={onCreateWorkRoleClick} color="primary" variant="contained">
              Create Role
            </Button>
          </Box>
        </Controls>
        <ListPlacement>
          <List>
            {workRoles.map((wR: WorkRole, idx) => (
              <WorkRoleListItem
                key={`WorkRole:${idx}`}
                onItemSelect={onItemSelect}
                expanded={!!selectedWorkRoleMap?.[wR.id as string]}
                workRole={wR}
                onShowMatchingProfiles={onShowMatchingProfilesInternal}
              >
                <ListItemIcon>
                  <IconButton value={wR.id} onClick={onEditWorkRoleClick}>
                    <Edit />
                  </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                  <IconButton value={wR.id} onClick={onDeleteWorkRoleClick}>
                    <Delete />
                  </IconButton>
                </ListItemIcon>
              </WorkRoleListItem>
            ))}
          </List>
        </ListPlacement>
      </WorkRolesListLayout>
    </>
  );
};
