import React, { Fragment, Children, createContext, FC, PropsWithChildren, useReducer, useRef, useEffect } from 'react';

const { Provider: LayoutProvider, Consumer: LayoutConsumer } = createContext(new Map());

type LayoutPositionFC = { instId: {}; Portal: FC<PropsWithChildren<any>> } & FC<any>;

export const createLayoutPosition = (): LayoutPositionFC => {
  const instId = {};
  const portalUpdateId = {};
  const LayoutPosition: LayoutPositionFC = () => {
    const [_ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    return (
      <LayoutConsumer>
        {(positionMap = new Map()) => {
          positionMap.set(portalUpdateId, forceUpdate);

          return <Fragment>{positionMap.get(instId)}</Fragment>;
        }}
      </LayoutConsumer>
    );
  };

  LayoutPosition.Portal = (({ children }) => {
    const positionMapRef = useRef<Map<any, any> | undefined>();
    const updatePortalRef = useRef<(() => undefined) | undefined>(undefined);
    const update = () => {
      if (updatePortalRef.current) {
        updatePortalRef.current();
      }
    };

    // Always update.
    useEffect(update);

    // Clear portal children on unmount.
    useEffect(() => {
      return () => {
        if (positionMapRef.current) {
          positionMapRef.current.set(instId, undefined);
        }

        update();
      };
    }, []);

    return (
      <LayoutConsumer>
        {(positionMap = new Map()) => {
          const updatePortal: () => undefined = positionMap.get(portalUpdateId);

          positionMap.set(instId, children);
          positionMapRef.current = positionMap;
          updatePortalRef.current = updatePortal;

          return null;
        }}
      </LayoutConsumer>
    );
  }) as FC<PropsWithChildren<any>>;
  LayoutPosition.instId = instId;

  return LayoutPosition;
};

export const createLayout = (Layout: FC<any>): FC<PropsWithChildren<any>> => ({ children, ...props }) => {
  const positionMap = new Map();

  Children.toArray(children)
    .filter((c?: any) => c instanceof Object && c.type instanceof Object)
    .forEach((elem?: any) => {
      const { type: { instId = undefined } = {}, props: { children: positionChildren = undefined } = {} } = elem || {};

      if (instId instanceof Object) {
        positionMap.set(instId, positionChildren);
      }
    });

  return (
    <LayoutProvider value={positionMap}>
      <Layout {...props} />
    </LayoutProvider>
  );
};
