import React, { FC } from 'react';
import { ItemController, ItemViewController } from '../Utils/ItemController';
import { IncarnateRoute, IncarnateRouteSet, LifePod } from '@incarnate/react';
import { Profile } from '../Types/Profile';
import { WorkRole } from '../Types/WorkRole';
import { Tab, Tabs } from '@material-ui/core';
import { WorkRoleSearch } from '../User/WorkRoleSearch';
import { SelectedWorkRoles } from '../User/SelectedWorkRoles';
import { FileController, FileViewController } from '../Utils/FileController';
import { ProfileView, ProfileViewProps } from '../User/ProfileView';
import { Position } from '../Types/Position';
import { Designation } from '../Types/Designation';
import { PositionsView } from '../User/PositionsView';
import { EmployerProfile } from '../Types/EmployerProfile';
import { EmployerProfileDetails } from '../User/EmployerProfileDetails';
import { WorkRoleDetails } from '../User/WorkRoleDetails';

export type CandidateProps = {
  onAfterDeleteProfile?: () => void;
};

export const Candidate: FC<CandidateProps> = ({ onAfterDeleteProfile }) => {
  return (
    <>
      <ItemController
        name="UserPositionController"
        shared={{
          ItemService: 'Services.UserPosition',
        }}
        preloadList
      />
      <ItemController
        name="UserDesignationController"
        shared={{
          ItemService: 'Services.UserDesignation',
        }}
        preloadList
      />
      <ItemController
        name="ProfileController"
        shared={{
          ItemService: 'Services.UserProfile',
        }}
      />
      <ItemController
        name="UserEmployerProfileController"
        shared={{
          ItemService: 'Services.UserEmployerProfile',
        }}
      />
      <ItemController
        name="UserWorkRoleController"
        shared={{
          ItemService: 'Services.UserWorkRole',
        }}
        preloadList
        debounceListUpdateMS={800}
      />
      <LifePod
        name="UserID"
        dependencies={{
          userId: 'Auth.UserID',
          viewController: 'ProfileController.ViewController',
        }}
        strict
        factory={(deps): string | undefined => {
          const {
            userId,
            viewController,
          }: {
            userId?: string;
            viewController: ItemViewController<Profile>;
          } = deps as any;

          if (userId) {
            viewController.loadItem(userId, ['selectedWorkRoles']);
          }

          return userId;
        }}
      />
      <LifePod
        name="ExistingProfile"
        dependencies={{
          userId: 'UserID',
          profileMap: 'ProfileController.Existing',
          viewController: 'ProfileController.ViewController',
        }}
        strict
        factory={(deps): Profile | undefined => {
          const {
            userId,
            profileMap,
            viewController,
          }: {
            userId?: string;
            profileMap?: { [key: string]: Profile };
            viewController: ItemViewController<Profile>;
          } = deps as any;

          if (userId && profileMap) {
            const profile: Profile | undefined = profileMap[userId];

            if (profile) {
              viewController.initChangingItem(userId);

              return profile;
            }
          }
        }}
      />
      <LifePod
        name="ProfileSelectedWorkRoleMap"
        dependencies={{
          profile: 'ExistingProfile',
        }}
        factory={(deps): { [key: string]: WorkRole } => {
          const {
            profile: { selectedWorkRoles = [] } = {},
          }: {
            profile?: Profile;
          } = deps as any;

          return selectedWorkRoles.reduce(
            (acc, wR: WorkRole) => ({
              ...(acc as any),
              [wR.id as string]: wR,
            }),
            {} as any
          );
        }}
      />
      <IncarnateRouteSet defaultSubPath="roles">
        <IncarnateRoute subPath="roles">
          <LifePod
            dependencies={{
              routeProps: 'ROUTE_PROPS',
            }}
            factory={(deps: any = {}) => {
              const possiblePaths = ['search', 'selected'];
              const { routeProps: { location: { pathname = '' } = {}, history = undefined } = {} } = deps;

              return {
                value: possiblePaths.filter((p) => new RegExp(`^.*?/${p}$`, 'gmi').test(pathname))[0] || 'search',
                onChange: (_e: any, v: string = '/') => history?.push(v === '/' ? v : `./${v}`),
              };
            }}
          >
            <Tabs>
              <Tab value="search" label="Search" />
              <Tab value="selected" label="Selected" />
            </Tabs>
          </LifePod>
          <br />
          <IncarnateRouteSet defaultSubPath="search">
            <IncarnateRoute subPath="search">
              <LifePod
                name="WorkRoleSearchLoading"
                dependencies={{
                  profileLoading: 'ProfileController.Pending.Any',
                  workRolesLoading: 'UserWorkRoleController.Pending.Any',
                }}
                factory={(deps) => Object.keys({ ...deps }).reduce((acc, p) => acc || deps[p], false)}
              />
              <LifePod
                dependencies={{
                  history: 'ROUTE_PROPS.history',
                  loading: 'WorkRoleSearchLoading',
                  params: 'UserWorkRoleController.ListParams',
                  workRoleList: 'UserWorkRoleController.ExistingItemList',
                  existingWorkRoleMap: 'UserWorkRoleController.Existing',
                  viewController: 'UserWorkRoleController.ViewController',
                  selectedWorkRoleMap: 'UserWorkRoleController.SelectedExisting',
                  profile: 'ExistingProfile',
                  profileViewController: 'ProfileController.ViewController',
                  profileSelectedWorkRoleMap: 'ProfileSelectedWorkRoleMap',
                }}
                mapToProps={({ history, ...p }) => ({
                  ...p,
                  onOpenEmployerProfileDetails: (employerProfileId: string) =>
                    history.push(`../employer-profile-details/${employerProfileId}`),
                })}
              >
                <WorkRoleSearch />
              </LifePod>
            </IncarnateRoute>
            <IncarnateRoute subPath="selected">
              <LifePod name="SelectedWorkRolesExpandedMap" factory={() => ({})} />
              <LifePod
                dependencies={{
                  loading: 'ProfileController.Pending.Any',
                  profile: 'ExistingProfile',
                  profileViewController: 'ProfileController.ViewController',
                  expandedMap: 'SelectedWorkRolesExpandedMap',
                }}
                setters={{
                  setExpandedMap: 'SelectedWorkRolesExpandedMap',
                }}
                mapToProps={(p) => p}
              >
                <SelectedWorkRoles />
              </LifePod>
            </IncarnateRoute>
          </IncarnateRouteSet>
        </IncarnateRoute>
        <IncarnateRoute subPath="role-details/:workRoleId">
          <LifePod
            name="WorkRoleDetailsID"
            dependencies={{
              routeProps: 'ROUTE_PROPS',
            }}
            factory={({ routeProps: { params: { workRoleId = undefined } = {} } = {} }) => workRoleId}
          />
          <LifePod
            name="WorkRoleDetailsLoader"
            dependencies={{
              workRoleId: 'WorkRoleDetailsID',
              viewController: 'UserWorkRoleController.ViewController',
            }}
            strict
            factory={({ workRoleId = '', viewController }) => {
              if (viewController && workRoleId) {
                const vC = viewController as ItemViewController<Profile>;

                // TRICKY: Delay loading the Profile until after the view has rendered once.
                setTimeout(() => {
                  vC.loadItem(workRoleId);
                }, 0);
              }
            }}
          />
          <LifePod
            name="ExistingWorkRole"
            dependencies={{
              workRoleId: 'WorkRoleDetailsID',
              existingItemMap: 'UserWorkRoleController.Existing',
            }}
            strict
            factory={({ workRoleId = '', existingItemMap = {} }) => existingItemMap[workRoleId]}
          />
          <LifePod
            dependencies={{
              history: 'ROUTE_PROPS.history',
              loading: 'UserWorkRoleController.Pending.Any',
              workRole: 'ExistingWorkRole',
            }}
            mapToProps={({ history, ...p }) => ({
              ...p,
              onOpenEmployerProfileDetails: (employerProfileId: string) =>
                history.push(`../employer-profile-details/${employerProfileId}`),
            })}
          >
            <WorkRoleDetails />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="employer-profile-details/:employerProfileId">
          <LifePod
            name="EmployerProfileDetailsID"
            dependencies={{
              routeProps: 'ROUTE_PROPS',
            }}
            factory={({ routeProps: { params: { employerProfileId = undefined } = {} } = {} }) => employerProfileId}
          />
          <LifePod
            name="EmployerProfileDetailsLoader"
            dependencies={{
              employerProfileId: 'EmployerProfileDetailsID',
              viewController: 'UserEmployerProfileController.ViewController',
            }}
            strict
            factory={({ employerProfileId = '', viewController }) => {
              if (viewController && employerProfileId) {
                // TRICKY: Delay loading the Profile until after the view has rendered once.
                setTimeout(() => {
                  (viewController as ItemViewController<EmployerProfile>).loadItem(employerProfileId, []);
                }, 0);
              }
            }}
          />
          <LifePod
            name="EmployerProfileDetailsData"
            dependencies={{
              existingEmployerProfileMap: 'UserEmployerProfileController.Existing',
              employerProfileId: 'EmployerProfileDetailsID',
            }}
            factory={({ existingEmployerProfileMap = {}, employerProfileId = '' }) =>
              employerProfileId ? existingEmployerProfileMap[employerProfileId] : undefined
            }
          />
          <LifePod
            dependencies={{
              employerProfile: 'EmployerProfileDetailsData',
              pending: 'UserEmployerProfileController.Pending.Any',
            }}
            mapToProps={(p) => p}
          >
            <EmployerProfileDetails />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="profile">
          <FileController
            name="UserResumeController"
            shared={{
              FileService: 'Services.UserResume',
            }}
          />
          <LifePod
            name="ProfileDataPending"
            dependencies={{
              resumePending: 'UserResumeController.Activity',
              profilePending: 'ProfileController.Pending.Any',
              positionsPending: 'UserPositionController.Pending.Any',
              designationsPending: 'UserDesignationController.Pending.Any',
            }}
            factory={(deps) => Object.keys({ ...deps }).reduce((acc, p) => acc || deps[p], false)}
          />
          <LifePod
            dependencies={{
              originalProfile: 'ExistingProfile',
              profile: 'ProfileController.LatestChangingItem',
              positionOptions: 'UserPositionController.ExistingItemList',
              designationOptions: 'UserDesignationController.ExistingItemList',
              viewController: 'ProfileController.ViewController',
              pending: 'ProfileDataPending',
              resumeViewController: 'UserResumeController.ViewController',
              resumeUploaded: 'UserResumeController.FileExists',
            }}
            mapToProps={(deps): ProfileViewProps => {
              const {
                originalProfile,
                profile,
                positionOptions,
                designationOptions,
                viewController,
                pending = false,
                resumeViewController,
                resumeUploaded,
              }: {
                originalProfile?: Profile;
                profile?: Profile;
                positionOptions?: Position[];
                designationOptions?: Designation[];
                viewController?: ItemViewController<Profile>;
                pending?: boolean;
                resumeViewController?: FileViewController;
                resumeUploaded?: boolean;
              } = deps as any;

              return {
                originalProfile,
                profile,
                positionOptions,
                designationOptions,
                viewController,
                pending,
                resumeViewController,
                resumeUploaded,
              };
            }}
          >
            <ProfileView onAfterDelete={onAfterDeleteProfile} />
          </LifePod>
        </IncarnateRoute>
        <IncarnateRoute subPath="positions">
          <LifePod name="UserPositionsSearchTerm" factory={() => ''} />
          <LifePod
            name="FilteredUserPositions"
            dependencies={{
              userPositionsSearchTerm: 'UserPositionsSearchTerm',
              positions: 'UserPositionController.ExistingItemList',
            }}
            factory={({ userPositionsSearchTerm = '', positions = [] }) => {
              if (userPositionsSearchTerm) {
                return positions.filter(({ name = '', description = '' }: Position = {}) => {
                  const lwrName = name.toLowerCase();
                  const lwrDesc = description.toLowerCase();
                  const lwrTerm = userPositionsSearchTerm.toLowerCase();
                  const termList: string[] = lwrTerm.split(' ').filter((t: string = '') => !!t);
                  const fullText = `${lwrName}\n${lwrDesc}`;

                  for (const t of termList) {
                    if (fullText.indexOf(t) !== -1) {
                      return true;
                    }
                  }

                  return false;
                });
              } else {
                return positions;
              }
            }}
          />
          <LifePod
            dependencies={{
              userPositionsSearchTerm: 'UserPositionsSearchTerm',
              positions: 'FilteredUserPositions',
              pending: 'UserPositionController.Pending.Any',
            }}
            setters={{
              onSearchTermChange: 'UserPositionsSearchTerm',
            }}
            mapToProps={(p) => p}
          >
            <PositionsView />
          </LifePod>
        </IncarnateRoute>
      </IncarnateRouteSet>
    </>
  );
};
