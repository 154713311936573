import React, { FC, useCallback, useMemo } from 'react';
import { Profile } from '../Types/Profile';
import { Details, HeaderRight, Layout, Subtitle, Title } from './ProfileDetails/Layout';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import { CheckBoxOutlineBlank, CheckBoxOutlined, LinkedIn, YouTube } from '@material-ui/icons';
import { WORK_ROLE_REGION_MAP } from './WorkRoles/Constants';
import NumberFormat from 'react-number-format';
import { getCleanWorkRoleRegionId } from './WorkRoles/Utils/WorkRoleRegionUtils';
import { shallowConvertNullToUndefined } from '../Utils/DataUtils';

export type ProfileDetailsProps = {
  profile?: Profile;
  pending?: boolean;
  isAdmin?: boolean;
  onProfileChange?: (profile: Profile) => void;
};

export const ProfileDetails: FC<ProfileDetailsProps> = ({
  profile = {},
  pending = false,
  isAdmin = false,
  onProfileChange,
}) => {
  const {
    approved = false,
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber = '',
    yearsOfExperience = 0,
    salary = 0,
    designations = [],
    positionsOfInterest = [],
    regionsOfInterest = [],
    linkedInLink = '',
    youtubeVideoLink = '',
    profileSummary = '',
    resumeDownloadUrl = '',
  } = shallowConvertNullToUndefined(profile) as Profile;
  const phoneNumberData: PhoneNumber | undefined = useMemo(
    () => (phoneNumber ? parsePhoneNumber(phoneNumber) : undefined),
    [phoneNumber]
  );
  const onProfileChangeInternal = useCallback(
    (newProfile: Profile) => {
      if (onProfileChange) {
        onProfileChange(newProfile);
      }
    },
    [onProfileChange]
  );
  const onToggleApproved = useCallback(
    () =>
      onProfileChangeInternal({
        ...profile,
        approved: !approved,
      }),
    [onProfileChangeInternal, profile, approved]
  );

  return (
    <>
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#ffffff',
        }}
        open={pending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layout>
        <Title>
          {firstName}&nbsp;{lastName}
        </Title>
        <HeaderRight>
          <Box flex="1 0 auto" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
            {youtubeVideoLink ? (
              <IconButton href={youtubeVideoLink} target="_blank" title="YouTube Video" rel="noreferrer">
                <YouTube color="primary" fontSize="large" />
              </IconButton>
            ) : undefined}
            {linkedInLink ? (
              <IconButton href={linkedInLink} target="_blank" title="LinkedIn" rel="noreferrer">
                <LinkedIn color="primary" fontSize="large" />
              </IconButton>
            ) : undefined}
            {resumeDownloadUrl ? (
              <Button href={resumeDownloadUrl} target="_blank" title="Resume" rel="noreferrer">
                Resume
              </Button>
            ) : undefined}
            {isAdmin ? (
              <Button
                onClick={onToggleApproved}
                title="Approval"
                startIcon={
                  approved ? (
                    <CheckBoxOutlined color="primary" fontSize="large" />
                  ) : (
                    <CheckBoxOutlineBlank color="primary" fontSize="large" />
                  )
                }
              >
                {approved ? 'Approved' : 'Approve'}
              </Button>
            ) : undefined}
          </Box>
        </HeaderRight>
        <Subtitle>
          <a href={email ? `mailto:${email}` : ''} target="_blank" rel="noreferrer">
            {email}
          </a>
          <br />
          <a href={phoneNumberData?.format('RFC3966') || ''} target="_blank" rel="noreferrer">
            {phoneNumberData?.formatInternational()}
          </a>
        </Subtitle>
        <Details>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Years of Experience
              </Typography>
              <Typography variant="h5" component="h2">
                {yearsOfExperience}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Desired Compensation
              </Typography>
              <Typography variant="h5" component="h2">
                <NumberFormat value={salary} displayType="text" thousandSeparator prefix="$" />
              </Typography>
            </CardContent>
          </Card>
          <Box flex="1 1 auto" display="grid" gridTemplateColumns="2fr 1fr">
            <Box
              flex="1 1 auto"
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="stretch"
              style={{ padding: '1em', whiteSpace: 'pre-wrap' }}
            >
              <Typography variant="body1">{profileSummary}</Typography>
            </Box>
            <List subheader={<li />}>
              <ListSubheader disableSticky>Positions of Interest</ListSubheader>
              {positionsOfInterest.map((item) => (
                <ListItem key={`POI:${item.id}`}>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
              <ListSubheader disableSticky>Regions of Interest</ListSubheader>
              {regionsOfInterest.map((item) => (
                <ListItem key={`ROI:${item}`}>
                  <ListItemText primary={WORK_ROLE_REGION_MAP[getCleanWorkRoleRegionId(item)]?.label} />
                </ListItem>
              ))}
              <ListSubheader disableSticky>Designations/Licenses</ListSubheader>
              {designations.map((item) => (
                <ListItem key={`DES:${item.id}`}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Details>
      </Layout>
    </>
  );
};
