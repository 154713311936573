import React, { FC } from 'react';
import { LifePod } from '@incarnate/react';
import { DBServiceClient } from '../../../Utils/DBServiceClient';
import { Profile } from '../../../Types/Profile';
import { AppEnvConfig } from '../../Types';

export type AdminServicesProps = {
  appEnvConfig: AppEnvConfig;
};

export const AdminServices: FC<AdminServicesProps> = ({ appEnvConfig }) => {
  return (
    <>
      <LifePod
        name="AdminProfile"
        dependencies={{
          user: 'Auth.User',
        }}
        strict
        factory={(deps) => {
          const {
            user: { id_token: idToken = '' } = {},
          }: {
            user?: {
              id_token?: string;
            };
          } = deps;

          return new DBServiceClient<Profile>(`${appEnvConfig.api.baseUrl}/admin/profile`, idToken);
        }}
      />
    </>
  );
};
