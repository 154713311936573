import React, { FC, useCallback } from 'react';
import { WorkRole } from '../Types/WorkRole';
import { Box, CircularProgress, IconButton, TextField, Typography } from '@material-ui/core';
import { ItemViewController } from '../Utils/ItemController';
import { Favorite } from '@material-ui/icons';
import { WorkRoleListItem } from './WorkRole/ListItem';
import { Profile } from '../Types/Profile';

export type WorkRoleSearchProps = {
  loading?: boolean;
  params?: WorkRole;
  workRoleList?: WorkRole[];
  existingWorkRoleMap?: { [key: string]: WorkRole };
  viewController?: ItemViewController<WorkRole>;
  selectedWorkRoleMap?: { [key: string]: WorkRole };
  profile?: Profile;
  profileViewController?: ItemViewController<Profile>;
  profileSelectedWorkRoleMap?: { [key: string]: WorkRole };
  onOpenEmployerProfileDetails?: (employerProfileId: string) => void;
};

export const WorkRoleSearch: FC<WorkRoleSearchProps> = ({
  loading = false,
  params = {},
  workRoleList = [],
  existingWorkRoleMap = {},
  viewController,
  selectedWorkRoleMap,
  profile = {},
  profileViewController,
  profileSelectedWorkRoleMap = {},
  onOpenEmployerProfileDetails,
}) => {
  const { title = '' } = params;
  const { id: profileId, selectedWorkRoles = [] } = profile;
  const profileObjectController = profileViewController?.getChangingItemObjectController(profileId as string);
  const onSearchChange = useCallback(
    ({ currentTarget: { value = '' } }) => {
      if (viewController) {
        viewController.onListParamsChange({
          title: value,
          description: value,
        });
      }
    },
    [viewController]
  );
  const onItemSelect = useCallback(
    (workRoleId: string) => {
      if (viewController) {
        viewController.toggleSelectItemById(workRoleId);
      }
    },
    [viewController]
  );
  const onAddWorkRoleClick = useCallback(
    ({ currentTarget: { value } }) => {
      const wR: WorkRole | undefined = existingWorkRoleMap[value as string];

      if (wR && profileObjectController) {
        profileObjectController.getFieldChangeHandler('selectedWorkRoles')([...selectedWorkRoles, wR]);
        profileObjectController.onSubmit();
      }
    },
    [profileObjectController, selectedWorkRoles, existingWorkRoleMap]
  );
  const onRemoveWorkRoleClick = useCallback(
    ({ currentTarget: { value } }) => {
      if (value && profileObjectController) {
        profileObjectController.getFieldChangeHandler('selectedWorkRoles')(
          selectedWorkRoles.filter((w) => w.id !== value)
        );
        profileObjectController.onSubmit();
      }
    },
    [profileObjectController, selectedWorkRoles]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      <TextField
        type="search"
        label="Search Roles"
        placeholder="Enter some search terms..."
        variant="outlined"
        value={title}
        onChange={onSearchChange}
      />
      <br />
      <Typography variant="h5">Available Roles</Typography>
      <br />
      {loading ? (
        <Box minHeight="10em" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {workRoleList.map((wR: WorkRole, idx) => {
            const isSelectedInProfile: boolean = !!profileSelectedWorkRoleMap[wR.id as string];
            const isSelectedInSearchList: boolean = !!selectedWorkRoleMap?.[wR.id as string];

            return (
              <WorkRoleListItem
                key={`WorkRole:${idx}`}
                workRole={wR}
                onItemSelect={onItemSelect}
                expanded={isSelectedInSearchList}
                onOpenEmployerProfileDetails={onOpenEmployerProfileDetails}
              >
                <IconButton value={wR.id} onClick={isSelectedInProfile ? onRemoveWorkRoleClick : onAddWorkRoleClick}>
                  <Favorite color={isSelectedInProfile ? 'secondary' : undefined} />
                </IconButton>
              </WorkRoleListItem>
            );
          })}
        </>
      )}
    </Box>
  );
};
