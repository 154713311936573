import React, { FC } from 'react';
import { WorkRole } from '../Types/WorkRole';
import { Box, CircularProgress } from '@material-ui/core';
import { WorkRoleListItem } from './WorkRole/ListItem';

export type WorkRoleDetailsProps = {
  loading?: boolean;
  workRole?: WorkRole;
  onOpenEmployerProfileDetails?: (employerProfileId: string) => void;
};

export const WorkRoleDetails: FC<WorkRoleDetailsProps> = ({
  loading = false,
  workRole,
  onOpenEmployerProfileDetails,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
      {loading ? (
        <Box minHeight="10em" display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <WorkRoleListItem
          workRole={workRole}
          expanded
          onOpenEmployerProfileDetails={onOpenEmployerProfileDetails}
          standalone
        />
      )}
    </Box>
  );
};
