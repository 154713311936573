import React, { ReactElement } from 'react';
import { Incarnate, ItemQueueProcessor, LifePod } from '@incarnate/react';
import { ObjectController } from './ObjectController';

export const DEFAULT_PRIMARY_KEY = 'id';

export const getTempItemIdPart = () => btoa(`${Math.ceil(Math.random() * 1000)}`);
export const getTempItemId = (numParts: number = 4) =>
  `TEMPORARY_IDENTIFIER_${[...new Array(numParts)].map(() => getTempItemIdPart()).join('-')}`;
export const getCleanControllerSubFieldPath = (parts: (string | undefined)[] = []) =>
  parts.filter((p) => !!p).join('.');

export type ItemMap<ItemType> = { [key: string]: ItemType };
export type ItemMapGetter<ItemType> = ((subPath: string) => ItemType) & (() => ItemMap<ItemType>);
export type ItemMapSetter<ItemType> = ((value: ItemType, subPath: string) => void) &
  ((value: ItemMap<ItemType>) => void);

export type ItemViewControllerConfig<ItemType> = {
  primaryKey?: keyof ItemType;
  getSelectedCreatingItemMap: ItemMapGetter<ItemType>;
  setSelectedCreatingItemMap: ItemMapSetter<ItemType>;
  getSelectedExistingItemMap: ItemMapGetter<ItemType>;
  setSelectedExistingItemMap: ItemMapSetter<ItemType>;
  getCreatingItemMap: ItemMapGetter<ItemType>;
  setCreatingItemMap: ItemMapSetter<ItemType>;
  setLatestCreatingItemId: (itemId: string) => void;
  getChangingItemMap: ItemMapGetter<ItemType>;
  setChangingItemMap: ItemMapSetter<ItemType>;
  setLatestChangingItemId: (itemId: string) => void;
  getExistingItemMap: ItemMapGetter<ItemType>;
  setExistingItemMap: ItemMapSetter<ItemType>;
  setListParams: (newValue: ItemType) => void;
  // Queues
  setCreateItemQueue: ItemMapSetter<ItemType>;
  setReadItemQueue: ItemMapSetter<ItemType>;
  setUpdateItemQueue: ItemMapSetter<ItemType>;
  setDeleteItemQueue: ItemMapSetter<ItemType>;
  // Relations
  setItemRelations: ItemMapSetter<string[]>;
};

export class ItemViewController<ItemType> {
  private _creatingObjectControllerCache: {
    [key: string]: ObjectController<ItemType>;
  } = {};
  private _changingObjectControllerCache: {
    [key: string]: ObjectController<ItemType>;
  } = {};

  constructor(public config: ItemViewControllerConfig<ItemType>) {
    if (typeof this.config.primaryKey === 'undefined') {
      this.config.primaryKey = DEFAULT_PRIMARY_KEY as keyof ItemType;
    }
  }

  toggleSelectedItem = ({
    itemId,
    existingItem,
    selected,
    getMap,
    setMap,
  }: {
    itemId: string;
    existingItem: ItemType;
    selected?: boolean;
    getMap: ItemMapGetter<ItemType>;
    setMap: ItemMapSetter<ItemType>;
  }) => {
    const { [itemId]: previouslySelectedItem, ...selectedItemMap } = getMap();
    const newSelectedItemMap: ItemMap<ItemType> = { ...selectedItemMap };
    const wasSelected: boolean = !!previouslySelectedItem;
    const shouldSelect: boolean = typeof selected !== 'undefined' ? selected : !wasSelected;

    if (shouldSelect && existingItem) {
      newSelectedItemMap[itemId] = existingItem;
    }

    setMap(newSelectedItemMap);
  };

  toggleSelectItemById = (itemId: string, selected?: boolean) => {
    const {
      getCreatingItemMap,
      getExistingItemMap,
      getSelectedCreatingItemMap,
      setSelectedCreatingItemMap,
      getSelectedExistingItemMap,
      setSelectedExistingItemMap,
    } = this.config;

    const { [itemId]: creatingItem }: ItemMap<ItemType> = getCreatingItemMap();
    const { [itemId]: existingItem }: ItemMap<ItemType> = getExistingItemMap();
    const itemIsBeingCreated: boolean = !!creatingItem;
    const toggleConfig = itemIsBeingCreated
      ? {
          itemId,
          existingItem: creatingItem,
          selected,
          getMap: getSelectedCreatingItemMap,
          setMap: setSelectedCreatingItemMap,
        }
      : {
          itemId,
          existingItem,
          selected,
          getMap: getSelectedExistingItemMap,
          setMap: setSelectedExistingItemMap,
        };

    this.toggleSelectedItem(toggleConfig);
  };

  /**
   * @returns {string} The temporary id for the new item.
   * */
  initCreatingItem = (): string => {
    const { primaryKey, setCreatingItemMap, setLatestCreatingItemId } = this.config;
    const tempId = getTempItemId();

    setCreatingItemMap({ [primaryKey as string]: tempId } as any, tempId);
    setLatestCreatingItemId(tempId);

    return tempId;
  };

  onCancelCreatingItem = (creatingItemId: string) => {
    const { getCreatingItemMap, setCreatingItemMap } = this.config;
    const { [creatingItemId]: creatingItem, ...otherCreatingItems } = getCreatingItemMap();

    setCreatingItemMap(otherCreatingItems);
  };

  getCreatingItemObjectController = (creatingItemId: string): ObjectController<ItemType> => {
    if (!this._creatingObjectControllerCache[creatingItemId]) {
      const { getCreatingItemMap, setCreatingItemMap } = this.config;

      this._creatingObjectControllerCache[creatingItemId] = new ObjectController<ItemType>(
        (subPath?: string) => getCreatingItemMap(getCleanControllerSubFieldPath([creatingItemId, subPath])),
        (newItem: ItemType, subPath?: string) =>
          setCreatingItemMap(newItem, getCleanControllerSubFieldPath([creatingItemId, subPath])),
        () => this.onSubmitCreatingItem(creatingItemId),
        () => this.onCancelCreatingItem(creatingItemId)
      );
    }

    return this._creatingObjectControllerCache[creatingItemId];
  };

  onSubmitCreatingItem = (creatingItemId: string) => {
    const { getCreatingItemMap, setCreatingItemMap, setCreateItemQueue } = this.config;
    const { [creatingItemId]: creatingItem, ...otherCreatingItems } = getCreatingItemMap();

    setCreateItemQueue(creatingItem, creatingItemId);
    setCreatingItemMap(otherCreatingItems);
  };

  loadItem = (itemId: string, relations: string[] = []) => {
    const { primaryKey, setReadItemQueue, setItemRelations } = this.config;

    // IMPORTANT: Set item relations first.
    setItemRelations(relations, itemId);
    setReadItemQueue({ [primaryKey as string]: itemId } as any, itemId);
  };

  /**
   * @returns {string} The id for the changing item.
   * */
  initChangingItem = (changingItemId: string): string => {
    const { getExistingItemMap, setChangingItemMap, setLatestChangingItemId } = this.config;
    const { [changingItemId]: changingItem } = getExistingItemMap();

    setChangingItemMap(changingItem, changingItemId);
    setLatestChangingItemId(changingItemId);

    return changingItemId;
  };

  onCancelChangingItem = (changingItemId: string) => {
    const { getChangingItemMap, setChangingItemMap } = this.config;
    const { [changingItemId]: creatingItem, ...otherChangingItems } = getChangingItemMap();

    setChangingItemMap(otherChangingItems);
  };

  getChangingItemObjectController = (itemId: string): ObjectController<ItemType> => {
    if (!this._changingObjectControllerCache[itemId]) {
      const { getChangingItemMap, setChangingItemMap } = this.config;

      this._changingObjectControllerCache[itemId] = new ObjectController<ItemType>(
        (subPath?: string) => getChangingItemMap(getCleanControllerSubFieldPath([itemId, subPath])),
        (newItem: ItemType, subPath?: string) =>
          setChangingItemMap(newItem, getCleanControllerSubFieldPath([itemId, subPath])),
        () => this.onSubmitChangingItem(itemId),
        () => this.onCancelChangingItem(itemId)
      );
    }

    return this._changingObjectControllerCache[itemId];
  };

  onSubmitChangingItem = (changingItemId: string) => {
    const { getChangingItemMap, setChangingItemMap, setUpdateItemQueue } = this.config;
    const { [changingItemId]: creatingItem, ...otherChangingItems } = getChangingItemMap();

    setUpdateItemQueue(creatingItem, changingItemId);
    setChangingItemMap(otherChangingItems);
  };

  onDeleteItem = (itemId: string) => {
    const { getExistingItemMap, setDeleteItemQueue } = this.config;
    const { [itemId]: itemToDelete } = getExistingItemMap();

    setDeleteItemQueue(itemToDelete, itemId);
  };

  onListParamsChange = (newListParams: ItemType) => {
    const { setListParams } = this.config;

    setListParams(newListParams);
  };
}

function itemListFactory<ItemType>({
  creating = {},
  existing = {},
}: {
  creating?: ItemMap<ItemType>;
  existing?: ItemMap<ItemType>;
} = {}): ItemType[] {
  const creatingList = Object.keys(creating).map((k) => creating[k]);
  const existingList = Object.keys(existing).map((k) => existing[k]);

  return [...creatingList, ...existingList].filter((i) => !!i);
}

const getMapFromItemList = (itemList: any[] = [], primaryKey: string = DEFAULT_PRIMARY_KEY) =>
  itemList
    .filter((i) => typeof i === 'object')
    .reduce(
      (acc, item) => ({
        ...acc,
        [item[primaryKey]]: item,
      }),
      {}
    );

function getCreateProcessor<ItemType>(service: IItemService<ItemType>) {
  return async (newItem: ItemType): Promise<ItemType> => {
    const newItemId: string = await service.create(newItem);

    return await service.read(newItemId);
  };
}

function getReadProcessor<ItemType>(
  service: IItemService<ItemType>,
  primaryKey: keyof ItemType = DEFAULT_PRIMARY_KEY as keyof ItemType,
  getRelations?: (itemId?: string) => Promise<string[]> | string[]
) {
  return async (itemToRead: ItemType): Promise<ItemType> => {
    const itemId: string = itemToRead[primaryKey] as any;

    let relations: string[] | undefined;

    if (getRelations) {
      relations = await getRelations(itemId);
    }

    if (!relations) {
      relations = [];
    }

    return await service.read(itemId, relations);
  };
}

function getUpdateProcessor<ItemType>(service: IItemService<ItemType>) {
  return async (changedItem: ItemType): Promise<ItemType> => {
    await service.update(changedItem);

    return changedItem;
  };
}

function getDeleteProcessor<ItemType>(
  service: IItemService<ItemType>,
  getExisting: ItemMapGetter<ItemType>,
  setExisting: ItemMapSetter<ItemType>,
  primaryKey: keyof ItemType = DEFAULT_PRIMARY_KEY as keyof ItemType
) {
  return async (itemToDelete: ItemType): Promise<ItemType> => {
    const itemId: string = itemToDelete[primaryKey] as any;
    const { [itemId]: discardedItem, ...otherExisting } = getExisting() || {};

    setExisting(otherExisting);
    await service.delete(itemId);

    return itemToDelete;
  };
}

export interface IItemService<ItemType> {
  create: (newItem: ItemType) => Promise<string>;
  read: (itemId: string, relations?: string[]) => Promise<ItemType>;
  update: (changedItem: ItemType) => Promise<boolean>;
  delete: (itemId: string) => Promise<boolean>;
  list: (criteria?: ItemType) => Promise<ItemType[]>;
}

/**
 * `pathDelimiter` is "."
 *
 * Available:
 * ViewController: @see ItemViewController
 * */
export type ItemControllerProps<ItemType> = {
  name: string;
  shared: {
    /**
     * @see IItemService
     * */
    ItemService: string;
  };
  primaryKey?: keyof ItemType;
  preloadList?: boolean;
  debounceListUpdateMS?: number;
};

export function ItemController<ItemType>({
  name,
  shared,
  primaryKey = DEFAULT_PRIMARY_KEY as keyof ItemType,
  preloadList = false,
  debounceListUpdateMS,
}: ItemControllerProps<ItemType>): ReactElement {
  const listParamsValue = preloadList ? {} : undefined;

  return (
    <Incarnate name={name} shared={shared} pathDelimiter=".">
      <LifePod name="SelectedCreating" factory={() => ({})} />
      <LifePod name="SelectedExisting" factory={() => ({})} />
      <LifePod
        name="SelectedItemList"
        dependencies={{
          creating: 'SelectedCreating',
          existing: 'SelectedExisting',
        }}
        factory={itemListFactory}
      />
      <LifePod name="Creating" factory={() => ({})} />
      <LifePod name="LatestCreatingItemId" factory={() => undefined} />
      <LifePod
        name="LatestCreatingItem"
        dependencies={{ itemId: 'LatestCreatingItemId', itemMap: 'Creating' }}
        factory={({ itemId, itemMap }) => itemMap?.[itemId]}
      />
      <LifePod name="Existing" factory={() => ({})} />
      <LifePod
        name="ItemList"
        dependencies={{
          creating: 'Creating',
          existing: 'Existing',
        }}
        factory={itemListFactory}
      />
      <LifePod
        name="ExistingItemList"
        dependencies={{
          existing: 'Existing',
        }}
        factory={itemListFactory}
      />
      <LifePod name="Changing" factory={() => ({})} />
      <LifePod name="LatestChangingItemId" factory={() => undefined} />
      <LifePod
        name="LatestChangingItem"
        dependencies={{ itemId: 'LatestChangingItemId', itemMap: 'Changing' }}
        factory={({ itemId, itemMap }) => itemMap?.[itemId]}
      />
      <LifePod name="Deleted" factory={() => ({})} />
      <LifePod name="ListParams" factory={() => listParamsValue} />
      <Incarnate name="Queues">
        <LifePod name="Create" factory={() => ({})} />
        <LifePod name="Read" factory={() => ({})} />
        <LifePod name="Update" factory={() => ({})} />
        <LifePod name="Delete" factory={() => ({})} />
      </Incarnate>
      <Incarnate name="Errors">
        <LifePod name="Create" factory={() => ({})} />
        <LifePod name="Read" factory={() => ({})} />
        <LifePod name="Update" factory={() => ({})} />
        <LifePod name="Delete" factory={() => ({})} />
        <LifePod name="List" factory={() => []} />
      </Incarnate>
      <LifePod name="ItemRelations" factory={() => ({})} />
      <Incarnate
        name="Processors"
        shared={{
          ItemService: 'ItemService',
          Existing: 'Existing',
          ItemRelations: 'ItemRelations',
        }}
      >
        <LifePod
          name="Create"
          dependencies={{
            service: 'ItemService',
          }}
          strict
          factory={({ service }) => getCreateProcessor<ItemType>(service)}
        />
        <LifePod
          name="Read"
          dependencies={{
            service: 'ItemService',
          }}
          getters={{
            getItemRelations: 'ItemRelations',
          }}
          strict
          factory={({ service, getItemRelations }) => getReadProcessor<ItemType>(service, primaryKey, getItemRelations)}
        />
        <LifePod
          name="Update"
          dependencies={{
            service: 'ItemService',
          }}
          strict
          factory={({ service }) => getUpdateProcessor<ItemType>(service)}
        />
        <LifePod
          name="Delete"
          dependencies={{
            service: 'ItemService',
          }}
          getters={{
            getExisting: 'Existing',
          }}
          setters={{
            setExisting: 'Existing',
          }}
          strict
          factory={({ service, getExisting, setExisting }) =>
            getDeleteProcessor<ItemType>(service, getExisting, setExisting, primaryKey)
          }
        />
      </Incarnate>
      <Incarnate
        name="Pending"
        shared={{
          // Remapped aliases.
          Create: 'CreateIQP.Processing',
          Read: 'ReadIQP.Processing',
          Update: 'UpdateIQP.Processing',
          Delete: 'DeleteIQP.Processing',
        }}
      >
        <LifePod
          name="Any"
          dependencies={{
            createPending: 'Create',
            readPending: 'Read',
            updatePending: 'Update',
            deletePending: 'Delete',
            listPending: 'List',
          }}
          factory={(deps) => Object.keys({ ...deps }).reduce((acc, p) => acc || deps[p], false)}
        />
        <LifePod name="List" factory={() => false} />
      </Incarnate>
      <LifePod
        name="ListPopulator"
        dependencies={{
          params: 'ListParams',
          service: 'ItemService',
        }}
        setters={{
          setExisting: 'Existing',
          setPendingList: 'Pending.List',
        }}
        strict
        debounceInvalidationMS={debounceListUpdateMS}
        factory={async (deps) => {
          const {
            params,
            service,
            setExisting,
            setPendingList,
          }: {
            params: ItemType;
            service: IItemService<ItemType>;
            setExisting: Function;
            setPendingList: Function;
          } = deps as any;

          let serviceError;

          if (service && setExisting) {
            setPendingList(true);

            try {
              const items = await service.list(params);

              setExisting(getMapFromItemList(items, primaryKey as string));
            } catch (error) {
              serviceError = error;
            }

            setPendingList(false);
          }

          if (serviceError) {
            throw serviceError;
          }

          return true;
        }}
      />
      <ItemQueueProcessor
        name="CreateIQP"
        shared={{
          InputMap: 'Queues.Create',
          OutputMap: 'Existing',
          ErrorMap: 'Errors.Create',
          ItemProcessor: 'Processors.Create',
        }}
        primaryKey={primaryKey as string}
      />
      <ItemQueueProcessor
        name="ReadIQP"
        shared={{
          InputMap: 'Queues.Read',
          OutputMap: 'Existing',
          ErrorMap: 'Errors.Read',
          ItemProcessor: 'Processors.Read',
        }}
        primaryKey={primaryKey as string}
      />
      <ItemQueueProcessor
        name="UpdateIQP"
        shared={{
          InputMap: 'Queues.Update',
          OutputMap: 'Existing',
          ErrorMap: 'Errors.Update',
          ItemProcessor: 'Processors.Update',
        }}
        primaryKey={primaryKey as string}
      />
      <ItemQueueProcessor
        name="DeleteIQP"
        shared={{
          InputMap: 'Queues.Delete',
          OutputMap: 'Deleted',
          ErrorMap: 'Errors.Delete',
          ItemProcessor: 'Processors.Delete',
        }}
        primaryKey={primaryKey as string}
      />
      <LifePod
        name="ViewController"
        getters={{
          getSelectedCreatingItemMap: 'SelectedCreating',
          getSelectedExistingItemMap: 'SelectedExisting',
          getCreatingItemMap: 'Creating',
          getChangingItemMap: 'Changing',
          getExistingItemMap: 'Existing',
        }}
        setters={{
          setSelectedCreatingItemMap: 'SelectedCreating',
          setSelectedExistingItemMap: 'SelectedExisting',
          setCreatingItemMap: 'Creating',
          setLatestCreatingItemId: 'LatestCreatingItemId',
          setChangingItemMap: 'Changing',
          setLatestChangingItemId: 'LatestChangingItemId',
          setExistingItemMap: 'Existing',
          setListParams: 'ListParams',
          // Queues
          setCreateItemQueue: 'Queues.Create',
          setReadItemQueue: 'Queues.Read',
          setUpdateItemQueue: 'Queues.Update',
          setDeleteItemQueue: 'Queues.Delete',
          // Relations
          setItemRelations: 'ItemRelations',
        }}
        factory={(config) =>
          new ItemViewController<ItemType>({
            primaryKey,
            ...config,
          } as ItemViewControllerConfig<ItemType>)
        }
      />
    </Incarnate>
  );
}
